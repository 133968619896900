import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useStore } from '../../../mobx-store/context'
import Search from '../Search/search'
import Table from '../Table/table'
import ReportMonthlyTable from './ReportMonthlyTable'
import { Wrap } from './left.style'

const Left = observer(
  ({
    isReportSource = false,
    isLicenseSource = false,
    isRenewalsReporting = false,
    auth,
    getCurrentLegislative,
    archived,
    monitor,
    all,
    apcd,
    changeTab,
    currentLegislativeLoading,
    active,
    currentPage,
    setCurrentPage,
    isMyTasks,
    complianceItem,
    onCreateNew,
    setTouched,
    setEditing,
    setVisible,
    touched,
    getData,
    onTabClick,
  }) => {
    const store = useStore()
    const [page, setPage] = useState(1)

    const legislativeTabs = [
      'main',
      'closed',
      'all',
      'monitor',
      'apcd',
      'renewals_reporting',
      'need',
      'held',
      'na',
      'not_assessed',
      'pending_tasks',
      'assigned',
      'all_renewals',
      'ia_all',
      'ia_unassigned',
      'ia_in_progress',
      'ia_in_progress_ready_for_final_review',
      'ia_in_progress_submitted_to_govt',
      'ia_in_progress_deficiency',
      'initial_application',
      'pending_closed',
      'ia_closed'
    ];

    const count = legislativeTabs.includes(active)
      ? store.legislatives?.count
      : store.tasks?.count;

    useEffect(() => {
      setCurrentPage(1)
      setPage(1)
    }, [active])

    const checkForTrial = () =>
      store.company.length > 0 && store.company[0].payment_status === 'trial'

    return (
      <Wrap>
        <Search
          auth={auth}
          closed={archived}
          all={all}
          all_renewals={active === 'all_renewals'}
          monitor={monitor}
          apcd={apcd}
          need={active === 'need'}
          held={active === 'held'}
          na={active === 'na'}
          not_assessed={active === 'not_assessed'}
          renewals_reporting={active === 'renewals_reporting'}
          pendingTasks={active === 'pending_tasks'}
          assignedTasks={active === 'assigned'}
          isTrial={checkForTrial()}
          onSearch={() => setCurrentPage(1)}
          isMyTasks={isMyTasks}
          activeTab={active}
          isReportSource={isReportSource}
          isLicenseSource={isLicenseSource}
          getData={getData}
        />
        {isReportSource && (active === 'main' || active === 'apcd')
          && <ReportMonthlyTable isReportSource={isReportSource} active={active} />
        }
        {isLicenseSource && (active === 'renewals_reporting' || active === 'apcd')
          && <ReportMonthlyTable isReportSource={isReportSource} isLicenseSource={isLicenseSource} active={active} />
        }

        <Table
          style={{ marginBottom: 200 }}
          count={count || 0}
          loading={currentLegislativeLoading}
          changeTab={(key) => changeTab(key)}
          getCurrentLegislative={(id) => getCurrentLegislative(id)}
          archived={archived}
          monitor={monitor}
          all={all}
          isTrial={checkForTrial()}
          currentPage={currentPage}
          onCurrentPageChange={(page) => setCurrentPage(page)}
          activeTab={active}
          isMyTasks={isMyTasks}
          complianceItem={complianceItem}
          onCreateNew={onCreateNew}
          setTouched={setTouched}
          setEditing={setEditing}
          setCreateItemVisible={setVisible}
          touched={touched}
          isReportSource={isReportSource}
          isLicenseSource={isLicenseSource}
          isRenewalsReporting={isRenewalsReporting}
          onTabClick={onTabClick}
        />
      </Wrap>
    )
  }
)

export default Left
