import React, { useState, useEffect } from "react";
import { useStore } from "../../../../mobx-store/context";
import { TabButton } from "./style";

const LicenseSelectFilter = ({ type, activeTab, onTabClick }) => {
  const store = useStore();

  // Локальное состояние для выбранной кнопки
  const [selectedOption, setSelectedOption] = useState(activeTab);

  const onReset = () => {
    setSelectedOption(activeTab);
    store.setSingleValue("mainFilterReset", false);
  };

  const renewalsTypes = [
    { name: "All", id: activeTab },
    { name: "Renewals", id: "renewals" },
    { name: "Reporting", id: "reports" },
  ];

  const iaTypes = [
    { name: "All", id: "ia_all" },
    { name: "Unassigned", id: "ia_unassigned" },
    { name: "In Progress", id: "ia_in_progress" },
    { name: "Ready for Final Review", id: "ia_in_progress_ready_for_final_review" },
    { name: "Submitted", id: "ia_in_progress_submitted_to_govt" },
    { name: "Deficiency", id: "ia_in_progress_deficiency" },
    // { name: "Closed", id: 'ia_closed' }
  ];

  const pendingStatuses = [
    { name: "All", id: "all" },
    { name: "Not Assessed", id: "not_assessed" },
    { name: "Monitor", id: "monitor" },
    { name: "N/A", id: "na" },
    // { name: 'Closed', id: 'pending_closed' }
  ];

  const handleRenewalsClick = (id) => {
    setSelectedOption(id);

    const searchObject = {
      show_items: id,
      source_type: "renewals_reporting",
      tab_name: activeTab,
      status: activeTab,
    };

    store.setSingleValue("searchableObject", searchObject);
    store.loadLegislatives({ page: 1, page_size: 20 }, false, true);
  };

  const handleDefaultClick = (id) => {
    setSelectedOption(id);
    onTabClick(id);
  };

  const handleClick = (id) => {
    if (type === "renewals") {
      handleRenewalsClick(id);
    } else {
      handleDefaultClick(id);
    }
  };


  useEffect(() => {
    if (store.mainFilterReset === true) {
      onReset();
    }
  }, [store.mainFilterReset]);

  const options = type === "pending"
    ? pendingStatuses
    : type === "ia"
      ? iaTypes
      : renewalsTypes;

  return (
    <div style={{ display: "flex", gap: "7px" }}>
      {options.map((option) => (
        <TabButton
          key={option.id}
          onClick={() => handleClick(option.id)}
          isSelected={selectedOption === option.id}
          enableMedia={options.length > 4}
        >
          {option.name}
        </TabButton>
      ))}
    </div>
  );
};

export default LicenseSelectFilter;


