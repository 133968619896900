import { useState } from 'react';
import { Button, ButtonWrapper } from './style';

const CustomTabs = ({ defaultState = 'pending_assessments', onTabChange }) => {
  const [activeButton, setActiveButton] = useState(defaultState);

  const handleClick = (buttonName) => {
    if (onTabChange) {
      onTabChange(buttonName);
    }
    setActiveButton(buttonName);
  };

  return (
    <ButtonWrapper>
      <Button
        active={activeButton === 'pending_assessments'}
        onClick={() => handleClick('pending_assessments')}
      >
        Pending Assessments
      </Button>
      <Button
        active={activeButton === 'initial_application'}
        onClick={() => handleClick('initial_application')}
      >
        Initial Application
      </Button>
      <Button
        active={activeButton === 'held'}
        onClick={() => handleClick('held')}
      >
        Held
      </Button>
      <Button
        active={activeButton === 'renewals_reporting'}
        onClick={() => handleClick('renewals_reporting')}
      >
        Renewals & Reporting
      </Button>
      <Button
        active={activeButton === 'tasks'}
        onClick={() => handleClick('tasks')}
      >
        My Tasks
      </Button>
      <Button
        active={activeButton === 'calendar'}
        onClick={() => handleClick('calendar')}
      >
        Calendar
      </Button>
    </ButtonWrapper>
  );
};

export default CustomTabs;
