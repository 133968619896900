import Modal from "../../../components/common/ModalNew"
import { Title, Description } from './style'
import Button from '../../../components/common/Button/button'
const WarningModal = ({ onClose, attemptsLeft = 'N/A', locked = false, inactive = false, deactivated = false }) => {
  return (
    <Modal
      throughOverlay={false}
      styleWrap={{ maxWidth: 480, padding: 40 }}
      onClose={onClose}
    >
      <Title locked={locked || inactive}> {locked ? 'Your Account is Locked' : inactive ? 'Your Account is Locked Due to Inacitivity' : deactivated ? 'Account Deactivated' : 'Incorrect credentials'}</Title>
      <Description>
        {locked ?
          <>Your account has been locked after multiple failed login attempts. Please contact an administrator or <a href='mailto:contact@pbmsource.com'>contact@pbmsource.com</a> if you need help.</>
          : inactive ? <>Please contact an administrator or  <a href='mailto:contact@pbmsource.com'>contact@pbmsource.com</a> to reactivate your account.</>
            : deactivated ? <>Please contact your company administrator or  <a href='mailto:contact@pbmsource.com'>contact@pbmsource.com</a> if you think this is an error.</>
              : <>Either your username or password is incorrect. After <span>{attemptsLeft} more fail login attempts</span> your account will be locked for 24 Hours.</>}
      </Description>
      {!locked && +attemptsLeft === 1 && <Description> Use the Forgot Your Password process or contact an administrator if you need help. </Description>
      }
      <div style={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
        <Button
          text='I Understand'
          style={{ marginTop: 20 }}
          onClick={onClose}
        />
      </div>
    </Modal>
  )

}

export default WarningModal