import { useState, useEffect } from 'react'
import { useStore } from '../../mobx-store/context'
import { useHistory } from 'react-router-dom'
import { Wrap, Title, SubTitle, ServiceBlocksWrap } from './style'
import CheckBox from '../../components/common/Checkbox'
import { ServiceBlock } from './ServiceBlock'
import resource_icon from '../../assets/images/resource_icon.png'
import proposed_icon from '../../assets/images/proposed_icon_new.png'
import report_icon from '../../assets/images/report_icon.png'
import compliance_icon from '../../assets/images/compliance_icon.png'
import license_icon from '../../assets/images/license_source_icon.png'
import FullScreenLoader from '../../components/common/FullScreenLoader'
import ChangePasswordModal from '../Auth/ChangePassowordModal'


export const ServiceSelection = () => {
  const [checked, setChecked] = useState(false)
  const [visibleModal, setvisibleModal] = useState(false)
  const [expiredPassword, setExpiredPassword] = useState(false)
  const store = useStore()
  const history = useHistory()
  const user = store.user[0]
  const company = store.company[0]

  useEffect(() => {
    setvisibleModal(localStorage.getItem('required_to_change_temporary_password') === 'true')
  }, [])

  useEffect(() => {
    setExpiredPassword(localStorage.getItem('is_time_to_change_password') === 'true')
  }, [])

  useEffect(() => {
    if (!user?.memorized_tool) {
      return
    }

    switch (user?.memorized_tool) {
      case 'compliance_source':
        history.push('/tracking/main');
        break;
      case 're_source':
        history.push('/resource/table/guides');
        break;
      case 'report_source':
        history.push('/report-source');
        break;
      case 'license_source':
        history.push('/license-source');
        break;
      case 'proposed_source':
        history.push('/proposed-source');
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    setChecked(false)

  }, [])


  const services = [
    {
      key: 0,
      name: 'Compliance',
      new: false,
      color: 'blue',
      description: 'Identifies new laws, regulations, and subregulatory guidance when adopted.',
      image: compliance_icon,
      link: '/tracking',
      disabled: !user?.have_access_to_tracking || !company?.have_access_to_tracking,
      signUpText: 'Reach Out To Sales',
    },
    {
      key: 1,
      name: 'Re',
      new: false,
      color: 'green',
      description: 'Identifies existing laws and regulations.',
      image: resource_icon,
      link: '/resource/table/guides',
      disabled: !user?.have_access_to_resource || !company?.have_access_to_resource,
      signUpText: 'Reach Out To Sales',
    },
    {
      key: 2,
      name: 'Report',
      new: false,
      color: 'orange',
      description: 'Organize, track and collaborate on reporting requirements.',
      image: report_icon,
      link: '/report-source',
      disabled: !user?.have_access_to_report_source || !company?.have_access_to_report_source,
      signUpText: 'Reach Out To Sales',
    },
    {
      key: 3,
      name: 'Proposed',
      new: false,
      color: 'text',
      description: 'Identifies proposed PBM legislation.',
      image: proposed_icon,
      link: '/proposed-source',
      disabled: !user?.have_access_to_proposed_source || !company?.have_access_to_proposed_source,
      signUpText: 'Reach Out To Sales',
    },
    {
      key: 4,
      name: 'License',
      new: true,
      color: 'violet',
      description: 'Track and collaborate on license and reporting requirements.',
      image: license_icon,
      link: '/license-source/',
      disabled: !user?.have_access_to_license_source || !company?.have_access_to_license_source,
      signUpText: 'Reach Out To Sales',
    }
  ]

  if (user?.memorized_tool) {
    return <FullScreenLoader />
  }

  return (
    <>
      {visibleModal && <ChangePasswordModal onClose={() => setvisibleModal(false)} />}
      {expiredPassword && <ChangePasswordModal onClose={() => setExpiredPassword(false)} expiredPassword />}
      <Wrap>
        <Title>Select Where You’d Like To Start</Title>
        <SubTitle style={{ display: 'flex' }}>
          <CheckBox style={{ marginRight: 5 }} checked={checked} onChange={() => { setChecked((prev) => !prev) }} />
          Remember My Selection (<i>Can be changed later</i>)
        </SubTitle>
        <ServiceBlocksWrap>
          {services.map((service, index) => {
            return (
              <ServiceBlock
                key={index}
                number={service.key}
                name={service.name}
                itemNew={service.new}
                description={service.description}
                image={service.image}
                color={service.color}
                link={service.link}
                checked={checked}
                disabled={service.disabled}
                signUpText={service.signUpText}
              />
            )
          }

          )}
        </ServiceBlocksWrap>
      </Wrap>
    </>
  )
}