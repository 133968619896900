import {
  ContentState,
  convertFromHTML,
  Editor,
  EditorState,
  RichUtils,
} from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import 'draft-js/dist/Draft.css'
import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { api } from '../../../../api'
import boldIcon from '../../../../assets/images/bold.svg'
import italicIcon from '../../../../assets/images/italic.svg'
import underlineIcon from '../../../../assets/images/underline.svg'
import Button from '../../../../components/common/Button/button'
import ButtonOrange from '../../../../components/common/ButtonOrange'
import { checkRole } from '../../../../helpers/helpers'
import { useStore } from '../../../../mobx-store/context'
import { DocumentsPlaceholder } from '../right.style'
import './DraftStyleDefault.css'
import Item from './Item'
import { Toolbar, ToolButton, Wrap, WrapEditor } from './style'
import EditorSaveModal from './EditorSaveModal'

const styleMap = {
  BOLD: {
    fontFamily: 'var(--bold)',
  },
}

const DetailsOld = ({
  legislative,
  isReportSource = false,
  reportId,
  isLicenseSource = false,
  isRenewalsReporting = false,
  type = 'details',
  disabled = false,
  getDataFromHistory = false,
  isNeedHistory = false,
}) => {

  const store = useStore()
  const params = useParams()
  const [data, setData] = useState(null)

  const [active, setActive] = useState({
    BOLD: false,
    ITALIC: false,
    UNDERLINE: false,
  })
  const editorBlockRef = useRef(null)
  const editorRef = useRef(null)
  const [visibleField, setVisibleField] = useState(false)
  const [loading, setLoading] = useState(false)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [showModal, setShowModal] = useState(false)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [pendingAction, setPendingAction] = useState(null)
  const isEmpty = !editorState.getCurrentContent().hasText()
  const ID = params.id || store.currentLegislative.id
  const historyLicenseId = isRenewalsReporting ? store.currentLegislative.license_company_id : store.currentLegislative.id
  const valueName = type === 'details' ? 'details_html' : 'assessment_html'

  const checkIsOnlySpaces = () => {
    const content = editorState.getCurrentContent()
    const isEditorEmpty = !content.hasText()
    const currentPlainText = content.getPlainText()
    const lengthOfTrimmedContent = currentPlainText.trim().length
    return !isEditorEmpty && !lengthOfTrimmedContent
  }

  const onSubmit = (onLoad) => {
    if (isEmpty || checkIsOnlySpaces()) return null

    setLoading(true)

    const value = stateToHTML(editorState.getCurrentContent())
    const obj = type === 'details' ? { details_html: value } : { assessment_html: value }
    const url = isReportSource
      ? `/report_company/${ID}/report/${store.currentLegislative.report.id}/details/`
      : isLicenseSource
        ? isRenewalsReporting ? `/renewals_reporting/${ID}/details/`
          : store.currentLegislative.status === 'held' || store.currentLegislative.status === 'needed'
            ? `/license_company/${ID}/details_html/`
            : `/license_company/${ID}/assessment_html/`
        : `/legislative-details/${ID}/`

    api(url, obj, 'PUT').then((data) => {
      if (!data.errors) {
        setData(data)
        setLoading(false)
        onLoad && onLoad()
      }
    })
  }

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)

    if (newState) {
      this.onChange(newState)
      return 'handled'
    }

    return 'not-handled'
  }

  const transformText = (e, type) => {
    setActive({ ...active, [type]: !active[type] })

    // onMouseDown and e.preventDefault because editor losses focus if you use onClick
    e.preventDefault()

    let nextState = RichUtils.toggleInlineStyle(editorState, type)

    setEditorState(nextState)
  }

  const handleModalClose = () => {
    setShowModal(false)
    setHasUnsavedChanges(false)
    if (editorRef.current) {
      editorRef.current.focus()
    }
  }

  const placeholderText = (() => {
    if (isReportSource) {
      return 'report assessment.';
    }

    if (
      isLicenseSource &&
      !isRenewalsReporting &&
      ['not_assessed', 'na', 'monitor'].includes(store?.currentLegislative?.status)
    ) {
      return 'pending assessment.';
    }

    if (
      store?.currentLegislative?.status === 'needed' &&
      params.tabName !== 'pending_tasks' &&
      params.tabName !== 'initial_application' &&
      params.tabName !== 'assigned'
    ) {
      return 'initial application.';
    }

    if (
      isRenewalsReporting ||
      params.tabName === 'pending_tasks' ||
      params.tabName === 'assigned' ||
      params.tabName === 'initial_application'
    ) {
      return 'license renewal.';
    }

    return 'compliance assessment.';
  })();

  // const detailsText = (() => {
  //   if (
  //     isLicenseSource &&
  //     !isRenewalsReporting &&
  //     ['not_assessed', 'na', 'monitor'].includes(store?.currentLegislative?.status)
  //   ) {
  //     return 'information';
  //   }

  //   return 'comments';
  // })();

  const memberRole = isLicenseSource || isRenewalsReporting
    ? [store.user?.[0].license_source_role]
    : isReportSource ? [store.user?.[0].report_source_role]
      : [store.user?.[0].member_type]

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!showModal
        && editorBlockRef.current
        && !editorBlockRef.current.contains(e.target)
        && !(isEmpty || checkIsOnlySpaces())
        && hasUnsavedChanges
      ) {
        e.preventDefault()
        e.stopPropagation()
        setShowModal(true)
        document.removeEventListener('click', handleClickOutside, true)
      }
    }
    if (hasUnsavedChanges) {
      document.addEventListener('click', handleClickOutside, true)
    }
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [showModal, hasUnsavedChanges, isEmpty])

  useEffect(() => {
    setData(null)
    const url = isReportSource
      ? `/report_company/${ID}/report/${store.currentLegislative.report.id}/`
      : isLicenseSource
        ? isRenewalsReporting && !isNeedHistory ? `/renewals_reporting/${ID}/details/`
          : valueName === 'details_html' ?
            isNeedHistory
              ? `/license_company/${historyLicenseId}/details_html/?for_history=true`
              : `/license_company/${ID}/details_html/`
            : `/license_company/${ID}/assessment_html/`
        : `/legislative-details/${ID}/`
    getDataFromHistory
      ? api(`/license_company/${historyLicenseId}/assessment_history/`, {}, 'GET').then((data) => {
        setData(data.assessment_html)
      })
      : api(url, {}, 'GET').then((data) => setData(data))
  }, [ID, store?.currentLegislative?.report?.id, store?.currentLegislative?.status])

  useEffect(() => {
    if (data?.[valueName]) {
      const blocksFromHTML = convertFromHTML(data[valueName])
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )
      setEditorState(EditorState.createWithContent(state))
      setVisibleField(false)
    } else {
      setEditorState(EditorState.createEmpty())
      getDataFromHistory || isNeedHistory ? setVisibleField(false) : setVisibleField(true)
    }
  }, [data])

  useEffect(() => {
    if (isEmpty)
      setActive({
        BOLD: false,
        ITALIC: false,
        UNDERLINE: false,
      })
  }, [isEmpty])

  return (
    <>
      {showModal && (
        <EditorSaveModal
          onSubmit={() => {
            onSubmit(handleModalClose)
          }}
          onClose={handleModalClose}
        />
      )}
      {data &&
        <div style={{ marginBottom: 50 }}>
          {checkRole(
            memberRole,
            ['admin', 'owner', 'compliance_owner', 'license_owner', 'report_owner']
          ) && visibleField && (
              <Wrap ref={editorBlockRef}>
                {store.currentLegislative.status !== 'held' &&
                  <div style={{
                    display: 'grid',
                    gridTemplateColumns: '90% 10%',
                    alignItems: 'center',
                    gap: 20,
                  }}>
                    <WrapEditor>
                      <Toolbar>
                        <ToolButton
                          onMouseDown={(e) => transformText(e, 'BOLD')}
                          active={active['BOLD']}
                        >
                          <img src={boldIcon} alt='bold-icon' />
                        </ToolButton>
                        <ToolButton
                          onMouseDown={(e) => transformText(e, 'ITALIC')}
                          active={active['ITALIC']}
                        >
                          <img src={italicIcon} alt='italic-icon' />
                        </ToolButton>
                        <ToolButton
                          onMouseDown={(e) => transformText(e, 'UNDERLINE')}
                          active={active['UNDERLINE']}
                        >
                          <img src={underlineIcon} alt='underline-icon' />
                        </ToolButton>
                      </Toolbar>
                      <div ref={editorRef}>
                        <Editor
                          editorState={editorState}
                          onChange={(editorState) => {
                            setEditorState(editorState)
                            setHasUnsavedChanges(true)
                          }}
                          handleKeyCommand={handleKeyCommand}
                          placeholder={`Type in relevant Notes for completing the ${placeholderText}`}
                          customStyleMap={{
                            ...editorState.getCurrentInlineStyle(),
                            ...styleMap,
                          }}
                        />
                      </div>
                    </WrapEditor>
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, justifyContent: 'center' }}>
                      <Button
                        text={'Save Note'}
                        style={{ fontSize: 12 }}
                        onClick={() => onSubmit(() => { })}
                        disabled={isEmpty || checkIsOnlySpaces()}
                        loading={loading}
                      />
                      {data?.[valueName] && (
                        <ButtonOrange
                          style={{ marginTop: 10, fontSize: 12 }}
                          text={'Cancel'}
                          onClick={() => setVisibleField(false)}
                        />
                      )}
                    </div>
                  </div>}
              </Wrap>
            )}
          {data?.[valueName] && <Item data={data} isLicenseSource={isLicenseSource} isReportSource={isReportSource} valueName={valueName} type={type} />}
          {!visibleField &&
            checkRole(
              memberRole,
              ['admin', 'owner', 'compliance_owner', 'license_owner', 'report_owner']
            ) && store.currentLegislative.status !== 'held' && !disabled && (
              <Button
                text={'Edit'}
                onClick={() => setVisibleField(true)}
                style={{ marginTop: 15 }}
              />
            )}

          {!data?.[valueName] && (
            <DocumentsPlaceholder
              style={{
                marginTop: 15,
                borderBottom: '1px solid #bbb',
                paddingBottom: 15,
              }}
            >
              There are no Notes associated with this item yet.
            </DocumentsPlaceholder>
          )}
        </div>
      }
    </>
  )
}

export default DetailsOld
