import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import closeIcon from '../../../../../../assets/images/close-grey.svg'
import deleteFileIcon from '../../../../../../assets/images/delete-orange.svg'
import Button from '../../../../../../components/common/Button/button'
import ButtonOrange from '../../../../../../components/common/ButtonOrange'
import { useStore } from '../../../../../../mobx-store/context'
import { checkRole } from '../../../../../../helpers/helpers'
import { Text } from '../../../right.style'
import { getFiles } from '../index'
import { Overlay, Title, Wrap, Subtitle } from '../style'
import { downloadDoc } from '../../../../../../api'
import DescriptionHistoryTooltip from './HistoryTooltip'
import FileList from '../FileList'

const ModalCompleted = ({ onClose, data, tasks, taskID, getTasks, isReportSource, isLicenseSource, isRenewalsReporting, handleTaskClick, setShouldComplete, complianceItem, disabled, currentDeficiencyBlockTasks, getDeficiencyTasksBlocks }) => {
  const store = useStore()
  const [files, setFiles] = useState([])

  const isDeficiencyTask = data.type === 'deficiency'

  const deleteFile = (name, id) => {
    const filtered = files.filter((item) => item.name !== name)
    setFiles(filtered)
    isReportSource
      ? store.deleteReportDocument(complianceItem, id, () => { isDeficiencyTask ? getDeficiencyTasksBlocks() : getTasks() })
      : isLicenseSource
        ? store.deleteLicenseDocument(complianceItem, id, () => { isDeficiencyTask ? getDeficiencyTasksBlocks() : getTasks() }, isRenewalsReporting)
        : store.deleteDocument(id, () => { isDeficiencyTask ? getDeficiencyTasksBlocks() : getTasks() })
  }

  useEffect(() => {
    if (isDeficiencyTask) {
      currentDeficiencyBlockTasks.length > 0 && setFiles(getFiles(currentDeficiencyBlockTasks, taskID))
    } else { tasks.length > 0 && setFiles(getFiles(tasks, taskID)) }
  }, [tasks])

  const description = data.description.includes(data.task_template)
    ? data.description
    : `${data.task_template ? data.task_template + ' ' : ''}${data.description}`

  return (
    <div>
      <Overlay />
      <Wrap>
        <img src={closeIcon} alt='close-icon' onClick={onClose} id='close' />
        <Title style={{
          marginBottom: 0,
        }}>
          {isReportSource || isLicenseSource
            ? data.type === 'get_ready_task'
              ? 'Completed CheckList Task'
              : 'Completed Task'
            : data.type !== 'default'
              ? 'Completed Considerations Task'
              : 'Completed Task'}
        </Title>
        <Subtitle>
          <strong>Assigned by: </strong>{store.getMember(data?.assigner)}
        </Subtitle>
        <Subtitle>
          <strong>Task: </strong>{description}{data.description_history.length > 0 && <DescriptionHistoryTooltip descriptionHistory={data.description_history} />}
        </Subtitle>
        <Title style={{
          fontSize: 16,
          marginTop: 25,
          marginBottom: 0,
        }}>
          Task Completed By:
        </Title>
        <Title
          style={{
            fontSize: 14,
            marginBottom: 0,
          }}
        >
          {store.getMember(data?.completed_by)}
        </Title>
        <span>
          <Moment format={'lll'}>{isReportSource ? data?.modified : data?.comments?.[0]?.created}</Moment>
        </span>
        <Title style={{ fontSize: 14, marginTop: 20, marginBottom: 0 }}>
          Task Comment*
        </Title>
        <Text style={{ margin: 0 }}>{isReportSource || isLicenseSource ? data?.comment : data?.comments?.[0]?.text}</Text>

        {files?.length && files?.length > 0 ? (
          <>
            <Title style={{ fontSize: 14, marginTop: 20, marginBottom: 0 }}>
              Uploaded Document
            </Title>

            <FileList
              temporaryFiles={files}
              setTemporaryFiles={setFiles}
              isLicenseSource={isLicenseSource}
              isReportSource={isReportSource}
              isRenewalsReporting={isRenewalsReporting}
              isModalCompleted={true}
              disabled={disabled}
              deleteFile={(name, id) => deleteFile(name, id)}
              onLoad={() => isDeficiencyTask ? getDeficiencyTasksBlocks() : getTasks()}
              downloadDoc={(id, name) => downloadDoc(
                id,
                name,
                isReportSource,
                isLicenseSource,
                isRenewalsReporting,
                store?.currentLegislative?.id,
                store?.currentLegislative?.report?.id
              )}
            />
            {/* {files.map((item) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ButtonOrange text={item.name}
                  onClick={() =>
                    downloadDoc(item.id, item.name, isReportSource, isLicenseSource, false, store?.currentLegislative?.id, store?.currentLegislative?.report?.id)}
                />
                <img
                  src={deleteFileIcon}
                  alt={'delete-icon'}
                  onClick={() => deleteFile(item.id,)}
                  style={{ marginLeft: 5, cursor: 'pointer' }}
                />
              </div>
            ))} */}
          </>
        ) : null}
        {!disabled &&
          <ButtonOrange
            style={{ color: 'var(--blue)', marginTop: 20 }}
            text={'Open Task Again'}
            onClick={() => {
              setShouldComplete(false)
              localStorage.setItem('taskReOpen', 'true')
              onClose()
              handleTaskClick(data, false)
            }}
          />
        }

        <Button
          text={'Close'}
          style={{ marginLeft: 'auto', marginTop: 20 }}
          onClick={onClose}
        />
      </Wrap>
    </div>
  )
}

export default ModalCompleted
