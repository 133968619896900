import { message, Popover, Dropdown, Menu } from 'antd'
import { useGA4React } from 'ga-4-react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import Moment from 'react-moment'
import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams, Link, } from 'react-router-dom'
import showdown from 'showdown'
import { api } from '../../../api'
import flag from '../../../assets/images/flag.svg'
import flagGrey from '../../../assets/images/flag_grey.svg'
import pdf from '../../../assets/images/pdf.svg'
import Button from '../../../components/common/Button/button'
import ButtonOrange from '../../../components/common/ButtonOrange'
import CalendarCustom from '../../../components/common/Calendar'
import Input from '../../../components/common/Input'
import Tabs from '../../../components/common/Tabs/tabs'
import useModal from '../../../hooks/useModal'
import image from '../../../assets/images/warning_big.svg'
import licenseEmpty from '../../../assets/images/license-empty.svg'
import {
  checkForTrial,
  checkRole,
  getDescription,
  getError,
  getStatusColor,
  getReportStatusColor,
  highlightSearchedWords,

} from '../../../helpers/helpers'
import { downloadDoc } from '../../../api'
import { useStore } from '../../../mobx-store/context'
import DeleteModal from './DeleteModal'
import { infoInputs, reportInfoInputs, lsInfoInputs } from './inputs'
import MoreInfo from './MoreInfo/moreInfo'
import {
  showTabs,
  showAssessmentTabs,
  showReportMaterialsTabs,
  showLsMaterialsTabs,
  showHeldMaterialsTabs,
  needmaterialsTabs,
  renewalsTabs,
  heldmaterialsTabs,
  heldAdminActionsTabs,
  reportMaterialsTabs,
  reportMaterialsTabsNotice,
  getTabs,
} from './tabs'
import NewRequirementBlock from './NewRequirementBlock/index'
import Tasks from './Tasks'
import HeldModal from './HeldModal'
import HeldAdminItemAddModal from './HeldAdminItemAddModal'
import Modal from './Modal'
import ConfirmModal from './ConfirmModal'
import TasksReAssignModal from './TaskReAssignModal'
import DeficiencyModal from './DeficiencyModal'
import MoveLicenseModal from './NoLongerHeldModal'
import RenewalCloseModal from './RenewalCloseModal'
import CertificateModal from './CertificateModal'
import CloseTasksModal from './CloseTasksModal'
import ImpactedClients from './Considerations/TagInput'
import InterLinks from './InterLinks'
import LicenseInfoBlock from './LicenceSourceBlocks/InfoBlock'
import DeterminationChangeConfirmModal from './DeterminationChangeConfirmModal'
import expandIcon from '../../../assets/images/expand.svg'
import hideIcon from '../../../assets/images/hide.svg'
import {
  Block,
  InternalDueDateBlock,
  DocumentWrap,
  RowWrap,
  Inputs,
  Label,
  LineOfBusiness,
  Read,
  Reference,
  Row,
  Text,
  Wrap,
  DisclaimerBlock,
  NotChoosen,
  BlockWrap,
  Circle,
  PriorsRow,
  HistoryTip,
} from './right.style'
import HeldHistoryBlock from './HeldHistoryBlock'
import { is } from 'immutable'
const converter = new showdown.Converter()

export const labelStyle = {
  color: 'var(--text)',
  fontFamily: 'var(--open-sans-semiBold)',
  fontSize: '0.875em',
}

const Right = observer(
  ({
    isReportSource = false,
    isLicenseSource = false,
    data,
    getCurrentLegislative,
    currentPage,
    isMyTasks,
    complianceItem,
    activeTab: mainActiveTab,
    onEdit,
    trackingMode,
    showModeBlocks,
    detailsState,
    setDetailsState,
    updateDetails,
  }) => {

    const params = useParams()
    const pendingAssessments = isLicenseSource && (params.tabName === 'all' || params.tabName === 'monitor' || params.tabName === 'na' || params.tabName === 'not_assessed')
    const isRenewalsReporting =
      params.tabName === 'renewals_reporting'
      || params.tabName === 'assigned'
      || (params.tabName === 'closed' && isLicenseSource)
      || params.tabName === 'all_renewals'
    const initialApplication = params.tabName === 'ia_all'
      || params.tabName === 'ia_unassigned'
      || params.tabName === 'ia_in_progress'
      || params.tabName === 'ia_in_progress_ready_for_final_review'
      || params.tabName === 'ia_in_progress_submitted_to_govt'
      || params.tabName === 'ia_in_progress_deficiency'
    const [visibleModal, setVisibleModal] = useState()
    const [visibleOwnerModal, setVisibleOwnerModal] = useState(false)
    const [visibleTaskAssignModal, setVisibleTaskAssignModal] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const [tasks, setTasks] = useState([])
    const [deficiencyTasks, setDeficiencyTasks] = useState([])
    const [historyTasks, setHistoryTasks] = useState([])
    const [archiveButtonLoading, setArchiveButtonLoading] = useState(false)
    const [assigneNew, setAssigneNew] = useState(false)
    const [successFile, setSuccessFile] = useState(null)
    const [trigger, setTrigger] = useState(false)
    const [activeMaterialsTab, setActiveMaterialsTab] = useState(isLicenseSource ?
      params.tabName === 'all'
        || params.tabName === 'monitor'
        || params.tabName === 'na'
        ? 'state_contract' : 'forms'
      : 'getReady')
    const [activeTab, setActiveTab] = useState(isLicenseSource
      ? data?.status === 'needed' || data?.status === 'held'
        ? 'details_pending'
        : isRenewalsReporting ?
          'details_pending'
          : 'assessment'
      : 'details')
    const [activeAssessmentHistoryTab, setActiveAssessmentHistoryTab] = useState('assessment_history')
    const [activeNeedHistoryTab, setActiveNeedHistoryTab] = useState('details_pending')
    const [activeLsMaterialsTab, setActiveLsMaterialsTab] = useState(
      params.tabName === 'all'
        || params.tabName === 'monitor'
        || params.tabName === 'na'
        ? data.status === 'needed' ? 'getReady' : 'getReady'
        : isRenewalsReporting ?
          'getReady_renewals'
          : 'getReady')
    const [activeIaHistoryTab, setActiveIaHistoryTab] = useState('getReady')
    const [activeAdminActionsTab, setActiveAdminActionsTab] = useState('admin_actions')
    const [considerationData, setConsiderationData] = useState(null)
    const [getReadyData, setGetReadyData] = useState(null)
    const [historyGetReadyData, setHistoryGetReadyData] = useState(null)
    const [visibleDeficiencyModal, setVisibleDeficiencyModal] = useState(false)
    const [visibleTaskCloseModal, setVisibleTaskCloseModal] = useState(false)
    const [visibleClosedSubmittedModal, setVisibleClosedSubmittedModal] = useState(false)
    const [visibleRenewalClosedModal, setVisibleRenewalClosedModal] = useState(false)
    const [renewalClosedStatus, setRenewalClosedStatus] = useState(null)
    const [visibleConfirmModal, setVisibleConfirmModal] = useState(false)
    const [visibleHeldModal, setVisibleHeldModal] = useState(false)
    const [determinationSelected, setDeterminationSelected] = useState(false)
    const [determinationConfirmModal, setDeterminationConfirmModal] = useState(false)
    const [tempDetermination, setTempDetermination] = useState(null)
    const [heldType, setHeldType] = useState('null')
    const [visibleHeldAddModal, setVisibleHeldAddModal] = useState(false)
    const [visibleNoLongerHeldModal, setVisibleNoLongerHeldModal] = useState(false)
    const [visibleAddCertificateModal, setVisibleAddCertificateModal] = useState(false)
    const [showDisabledBlock, setShowDisabledBlock] = useState(false)
    const [showDisabledAssessmentBlock, setShowDisabledAssessmentBlock] = useState(false)
    const [deficiencyEditMode, setDeficiencyEditMode] = useState(false)
    const [heldModalEditMode, setHeldModalEditMode] = useState(false)
    const [assessmentHistory, setAssessmentHistory] = useState(null)
    const [visibleDisabledTaskModal, setVisibleDisabledTaskModal] = useState(false)
    const [visibleIaDeficiencyModal, setVisibleIaDeficiencyModal] = useState(false)
    const [visibleSubmittedToGovModal, setVisibleSubmittedToGovModal] = useState(false)
    const [heldHistory, setHeldHistory] = useState(null)
    const [visibleConfirmPendingModal, setVisibleConfirmPendingModal] = useState(false)
    const history = useHistory()

    const getHeldHistory = () => {
      setHeldHistory(null)
      api(`/license_company/${id}/held_history/`, {}, 'GET').then(data => {
        if (data.errors) {
          return message.error('Error while fetching held history')
        }
        setHeldHistory(data)
      })
    }

    const openDisabledTaskModal = () => {
      setVisibleDisabledTaskModal(true)
    }

    const closeDisabledTaskModal = () => {
      setVisibleDisabledTaskModal(false)
    }

    const ga4React = useGA4React()
    const tabsRef = useRef(null);
    const { visible, handleClose, handleOpen } = useModal()
    const { control, reset, getValues, setValue } = useForm({
      defaultValues: {
        status: data.status,
        assign: data.assignee,
      },
    })
    const store = useStore()
    const [loading, setLoading] = useState({
      assignee: false,
      status: false,
      all: false,
      file: false,
      primary_due_date: false,
      deleting: false,
    })


    const { id } = data
    const reportId = data?.report?.id

    const confirmDelete = (isReportSource) => {
      setLoading((prev) => ({
        ...prev,
        deleting: true,
      }))
      const url = isReportSource ? `/report_company/${id}/report/` : `/legislative_edit/${id}/`
      api(url, {}, 'DELETE').then((data) => {
        if (data.errors) return message.error(getError(data))

        store.loadLegislatives({
          page: 1,
          page_size: 20,
        }, isReportSource)

        store.setSingleValue('currentLegislative', null)
        history.push(isReportSource ? '/report-source/main' : '/tracking/main')
        setDeleting(false)
        setLoading((prev) => ({
          ...prev,
          deleting: false,
        }))
      })
    }

    const deleteLicenseCertificate = (certificateId) => {
      const url = `/license_company/${data.id}/certificates/${certificateId}/`
      api(url, {}, 'DELETE').then((data) => {
        if (!data.errors) {
          getCurrentLegislative(store.currentLegislative.id, null, null, true, isReportSource, isLicenseSource)
        }
      })
    }

    const toggleBlock = () => {
      setShowDisabledBlock(!showDisabledBlock)
    }

    const onDeficientyEditMode = (onEdit) => {
      setDeficiencyEditMode(true)
      isRenewalsReporting
        ? setVisibleDeficiencyModal(true)
        : setVisibleIaDeficiencyModal(true)

      onEdit && onEdit()
    }

    const toggleAssessmentBlock = () => {
      setShowDisabledAssessmentBlock(!showDisabledAssessmentBlock)
    }

    const getAssessmentHistory = (id) => {
      api(`/license_company/${id}/assessment_history/`, {}, 'GET').then((res) => {
        setAssessmentHistory(res)
        store.setSingleValue('assessmentComments', res.comments)
        store.setSingleValue('assessmentImpactedClients', res.impacted_clients)
      })
    }

    const onDelete = () => setDeleting(true)

    const modifyDescription = (store, data) => {
      if (getDescription(store, data)) {
        return getDescription(store, data)
          .split(',')
          .join('<br />')
      }
      getDescription(store, data)
    }

    const isAdminOrComplianceOwner = checkRole([store.user?.[0]?.member_type], ['admin', 'compliance_owner']);
    const isAdminOrReportOwner = checkRole([store.user?.[0]?.report_source_role], ['admin', 'report_owner']);
    const isAdminorLicenseOwner = checkRole([store.user?.[0]?.license_source_role], ['admin', 'license_owner']);

    const accessAllowed = isAdminOrComplianceOwner || isAdminOrReportOwner || isAdminorLicenseOwner;

    const heldReferences = [
      {
        key: `held1`,
        title: 'License Number',
        description: data?.license_number || 'See Notes',
      },
      {
        key: `held2`,
        title: 'Date License Obtained',
        description: moment(data?.date_license_obtained).format('MM/DD/YYYY') || 'See Notes',
      },

      {
        key: `held3`,
        title: 'Renewal Date',
        description: data?.is_perpetual_license ? 'Perpetual' : moment(data?.renewal_date).format('MM/DD/YYYY') || 'See Notes',
      },
      {
        key: `held6`,
        title: 'State Mandated Exp. Date',
        description: data?.is_perpetual_license ? 'Perpetual' : moment(data?.expiration_date).format('MM/DD/YYYY') || 'See Notes',
      },
      {
        key: `held4`,
        title: 'View License Certificates:',
        description: data?.held_certificates
          ? <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <ButtonOrange text={'Add Certificate/Edit Certificate'} key={'add_certificate'} style={{ color: 'var(--blue)', fontSize: 12 }} onClick={() => setVisibleAddCertificateModal(true)} />
            {data?.held_certificates.map((item, index) => (
              <div
                onClick={() => downloadDoc(`license_company/${data?.id}/certificates/${item.id}/`, item.file_name, false, true, false, null, null, true)}
                style={{
                  fontSize: 12,
                  fontFamily: 'var(--regular)',
                  color: 'var(--orange)', textDecoration: 'underline',
                  cursor: 'pointer'
                }} key={index}>
                {item.file_name}
              </div>
            ))}
          </div>
          : 'See Notes',
      }
    ]



    const references = isLicenseSource ?
      isRenewalsReporting ?
        [{
          key: 9,
          title: 'Due Date',
          description: highlightSearchedWords(moment(data?.renewal_date).format('MM/DD/YYYY')),
        },
        {
          key: 6,
          title: 'Jurisdiction',
          description: highlightSearchedWords(getDescription(store.license_source_renewals_jurisdictions, data?.license?.jurisdiction), store?.searchableObject?.search),
        },
        {
          key: 10,
          title: 'License Type',
          description: highlightSearchedWords(getDescription(store.license_renewals_types, data?.license?.license_type, 'name'), store?.searchableObject?.search),
        },
        {
          key: 11,
          title: 'Line of Business',
          description: modifyDescription(store.license_source_renewals_line_of_business, data?.license?.line_of_business)
        }]
        : [
          {
            key: 6,
            title: 'Jurisdiction',
            description: highlightSearchedWords(getDescription(store.license_source_pending_jurisdictions, store?.currentDetermination?.license_determination?.jurisdiction), store?.searchableObject?.search),
          },
          {
            key: 7,
            title: 'License Type',
            description: highlightSearchedWords(getDescription(store.license_pending_types, store?.currentDetermination?.license_determination?.license_type, 'name'), store?.searchableObject?.search),
          },
          {
            key: 8,
            title: 'Line of Business',
            description: modifyDescription(store.license_source_pending_line_of_business, store?.currentDetermination?.license_determination?.line_of_business)
          }
        ] : [
        {
          key: 0,
          title: isReportSource ? 'Report Title' : 'Reference #',
          description: isMyTasks ?
            isReportSource
              ? highlightSearchedWords(data?.report?.report_title, store?.searchableObjectTasks?.search)
              : highlightSearchedWords(data?.reference_number, store?.searchableObjectTasks?.search)
            : isReportSource
              ? highlightSearchedWords(data?.report?.report_title, store?.searchableObject?.search)
              : highlightSearchedWords(data?.reference_number, store?.searchableObject?.search),
        },
        {
          key: 1,
          title: (
            <LineOfBusiness>
              Line of Business{' '}

            </LineOfBusiness>
          ),
          description:
            isReportSource
              ? modifyDescription(store.lineOfBusinesses, data?.report?.line_of_business)
              : modifyDescription(store.lineOfBusinesses, data?.line_of_business),
          hide: isReportSource
            ? true
            : trackingMode === 'compliance_source_ultra_lite'
              ? true
              : !store.company[0]?.is_premium,
        },
        {
          key: 2,
          title: isReportSource ? 'Report Type' : 'Topic',
          description: isReportSource
            ? getDescription(store.reportTypes, data?.report?.report_type)
            : isMyTasks
              ? highlightSearchedWords(modifyDescription(store.topics, data.topics), store?.searchableObjectTasks?.search)
              : highlightSearchedWords(modifyDescription(store.topics, data.topics), store?.searchableObject?.search),
          hide: isReportSource
            ? false
            : trackingMode === 'compliance_source_ultra_lite'
              ? true
              : false,
        },
        {
          key: 3,
          title: 'Sub-Topic',
          hide: isReportSource
            ? true
            : trackingMode === 'compliance_source_ultra_lite'
              ? true
              : !data.sub_topics.length
                ? true
                : !store.company[0]?.is_premium,
          description: isMyTasks
            ? highlightSearchedWords(modifyDescription(store?.legislative_options?.sub_topics, data.sub_topics), store?.searchableObjectTasks?.search)
            : highlightSearchedWords(modifyDescription(store?.legislative_options?.sub_topics, data.sub_topics), store?.searchableObject?.search)
        },
        {
          key: 4,
          title: 'Jurisdiction',
          description: isReportSource
            ? getDescription(store.jurs, data?.report?.jurisdiction)
            : getDescription(store.jurs, data.jurisdiction),
        },
        {
          key: 5,
          title: 'Adopted Date',
          description: moment(data.adopted_date).format('MM/DD/YYYY'),
          hide: isReportSource
            ? true
            : trackingMode === 'compliance_source_ultra_lite'
              ? false
              : true,
        },
      ]

    const statsStatus = activeTab === 'apcd' ? 'status=apcd' : 'status=actual'

    const showtabs = isLicenseSource ?
      (determinationSelected || isRenewalsReporting) ? true : false
      : showModeBlocks

    const validValues = [
      'needed',
      'not_assessed',
      'held',
      'monitor',
      'na',
      'ia_all',
      'ia_unassigned',
      'ia_in_progress',
      'ia_in_progress_ready_for_final_review',
      'in_progress_ready_for_final_review',
      'ia_in_progress_submitted_to_govt',
      'in_progress_submitted_to_govt',
      'ia_in_progress_deficiency',
      'ia_closed_approved',
      'closed',
      'closed_na',
      'closed_submitted',
      'in_progress',
      'all_tasks_completed',
      'ready_for_final_review',
      'in_progress_deficiency',
    ];

    const onSubmitDeficiency = (values, tasks, licenseCompanyId, files, type, editMode, currentDeficiencyId) => {

      const deficiencyObj = {
        deficiency_date: moment(values.deficiency_date).format('YYYY-MM-DD'),
        due_date: moment(values.deficiency_due_date).format('YYYY-MM-DD'),
        subject: values.subject,
        description: values.description,
      };

      const url = type === 'ia_deficiency'
        ? `/license_company/${licenseCompanyId}/deficiency/${editMode ? currentDeficiencyId + '/' : ''}`
        : `/renewals_reporting/${licenseCompanyId}/deficiency/${editMode ? currentDeficiencyId + '/' : ''}`

      api(url, deficiencyObj, editMode ? 'PATCH' : 'POST')
        .then((data) => {
          if (data.errors) {
            return message.error(getError(data));
          }

          store.setCurrentLegislative(data, true)
          store.loadActivity(id, 1, null, isReportSource, isLicenseSource, isRenewalsReporting, reportId)
          type === 'deficiency'
            ? store.setSingleValue('newItemStatus', { id: id, status: 'in_progress_deficiency' })
            : store.setSingleValue('newItemStatus', { id: id, status: 'ia_in_progress_deficiency' })

          const tasksObj = tasks.map((item) => ({
            assignee: item.owner,
            due_date: moment(item.dueDate).format('YYYY-MM-DD'),
            description: item.description,
            is_deficiency_task: true,
            deficiency: data.deficiency_id,
            type: 'deficiency',
            [type === 'ia_deficiency' ? 'license_company' : 'renewal_license']: licenseCompanyId,
            is_deficiency_task: true,
          }));

          const taskUrl = type === 'ia_deficiency'
            ? `/license_company/${licenseCompanyId}/deficiency/tasks/`
            : `/renewals_reporting/${licenseCompanyId}/deficiency/tasks/`

          if (tasks.length > 0 && !editMode) {
            api(taskUrl, { tasks: tasksObj }, 'POST')
              .then((taskResponse) => {
                if (taskResponse.errors) {
                  return message.error(getError(taskResponse));
                }
                getDeficiencyTasksBlocks(licenseCompanyId)
                return message.success('Deficiency created successfully');
              });
          } else {
            message.success('Deficiency created successfully');
          }
          if (files.length > 0) {
            const newFiles = files.filter((item) => Array.isArray(item))
            newFiles.forEach((item) => {
              postFile(null, null, item[0], false, 'deficiency', true, data.deficiency_id)
            })
          }
        })
        .catch((error) => {
          console.error('Error creating deficiency:', error);
          message.error('An unexpected error occurred. Please try again.');
        });

    };

    const handleChange = (value, type, check = true) => {
      setLoading((prev) => ({
        ...prev,
        [type]: true,
      }))

      const allDeficiencyTasksCompleted = (deficiencies) => {
        if (deficiencies.length < 1) return true;
        const result = deficiencies.every(deficiency =>
          deficiency.tasks.every(task => task.status !== 'incomplete')
        );
        return result;
      };

      isReportSource && localStorage.getItem('reportInLs') !== 'true' &&
        api(`/report_company/stats/?${statsStatus}`, {}, 'GET').then((data) => {
          store.setSingleValue('reportStats', data)
        })
      if (
        validValues.includes(value)) {
        if (data.status === 'needed' ? data.initial_application_assignee === null : data.assignee === null) {
          setVisibleOwnerModal(true)
          initialApplication ?
            setValue('initial_application_status', data.initial_application_status)
            : setValue('status', data.status)
          return
        }
      }
      if (value === 'in_progress_deficiency') {
        setVisibleDeficiencyModal(true)
        setValue('status', data.status)
        return
      }
      if (value === 'ia_in_progress_deficiency') {
        setVisibleIaDeficiencyModal(true)
        setValue('initial_application_status', data.initial_application_status)
        return
      }
      if (value === 'ia_in_progress_submitted_to_govt' || value === 'in_progress_submitted_to_govt') {
        setVisibleSubmittedToGovModal(true)
        isRenewalsReporting
          ? setValue('status', data.status)
          : setValue('initial_application_status', data.initial_application_status)
        return
      }

      if ((data.status === 'not_assessed' || data.status === 'na' || data.status === 'monitor') && value === 'needed') {
        if (store.legislativeTasks?.find((item) => item.status !== 'complete')) {
          setVisibleTaskCloseModal(true)
          setValue('status', data.status)
          setActiveTab('details_pending')
          return
        }
      }

      if (value === 'needed') {
        // if (mainActiveTab !== 'not_assessed' && mainActiveTab !== 'na' && mainActiveTab !== 'monitor') {
        // getCurrentLegislative(store.currentLegislative.id, null, null, true, isReportSource, isLicenseSource)
        getAssessmentHistory(store.currentLegislative.id)
        setActiveTab('details_pending')
        // }
      }
      if (value === 'not_assessed' || value === 'na' || (data.status === 'monitor' && isLicenseSource)) {
        setActiveTab('assessment')
      }
      if ((value === 'held' || value === 'ia_closed_approved')) {
        if ((store.legislativeTasks?.find((item) => item.status !== 'complete')) || !allDeficiencyTasksCompleted(deficiencyTasks)) {
          setVisibleTaskCloseModal(true)
          initialApplication
            ? setValue('initial_application_status', data.initial_application_status)
            : setValue('status', data.status)
          return
        } else {
          setVisibleHeldModal(true)
          initialApplication
            ? setValue('initial_application_status', data.initial_application_status)
            : setValue('status', data.status)
          return
        }
      }
      if ((!isRenewalsReporting || localStorage.getItem('reportInLs') === 'true' || isReportSource)
        && value === 'closed_submitted'
        && data.assignee !== null
        && !(store.legislativeTasks?.find((item) => item.status !== 'complete'))) {
        setVisibleClosedSubmittedModal(true)
        setValue('status', data.status)
        return
      }
      const isRenewalClosing = isRenewalsReporting
        && ['closed_submitted', 'closed_approved'].includes(value)
        && data.type === 'renewal'
        && data.assignee !== null;

      const areLegislativeTasksComplete = !(store.legislativeTasks?.some((item) => item.status !== 'complete'));

      if (isRenewalClosing) {
        if (areLegislativeTasksComplete && allDeficiencyTasksCompleted(deficiencyTasks)) {
          setVisibleRenewalClosedModal(true);
          setRenewalClosedStatus(value);
          setValue('status', data.status);
          return;
        } else {
          setVisibleTaskCloseModal(true)
          setValue('status', data.status)
          return
        }
      }
      if (value === 'closed' || value === 'closed_submitted' || value === 'closed_na' || value === 'closed_approved') {
        if (data.assignee === null) {
          setVisibleOwnerModal(true)
          setValue('status', data.status)
          return
        }
        if (
          store.legislativeTasks?.find((item) => item.status !== 'complete')
        ) {
          setVisibleModal(true)
          setValue('status', data.status)
          return
        }
      }
      if ((value === 'unassigned' || value === 'ia_unassigned') && check === true) {
        if (isReportSource
          ? data.is_report_owner_tasks_assigner
          : isLicenseSource && isRenewalsReporting
            ? data.is_renewal_owner_tasks_assigner
            : isLicenseSource && initialApplication
              ? data.is_license_owner_tasks_assigner
              : data.is_compliance_owner_tasks_assigner) {
          setVisibleTaskAssignModal(true)
          initialApplication ? setValue('initial_application_status', data.initial_application_status) : setValue('status', data.status)
          return
        }
      }
      if (value === 'needed') {
        setActiveLsMaterialsTab('getReady')
      }
      const url = isReportSource
        ? `/report_company/${data.id}/details/`
        : isLicenseSource ?
          isRenewalsReporting
            ? `/renewals_reporting/${data.id}/details/`
            : `/license_company/${data.id}/details/`
          : `/legislative/${data.id}/`

      const adjustedType =
        data.status === 'needed'
          ? type === 'internal_due_date'
            ? 'initial_application_internal_due_date'
            : type === 'assignee'
              ? 'initial_application_assignee'
              : type === 'status'
                ? 'initial_application_status'
                : type
          : type;

      if ((type === 'status' || type === 'initial_application_status') && (value !== data.status || value !== data.initial_application_status)) {
        store.setSingleValue('currentItemStatusChanged', true);
      }

      const formattedValue =
        (adjustedType === 'internal_due_date' || adjustedType === 'primary_due_date' || adjustedType === 'initial_application_internal_due_date')
          ? moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD')
          : isNaN(value) ? value : parseInt(value);

      const computedStatus =
        isLicenseSource
          ? (adjustedType === 'assignee' || adjustedType === 'initial_application_assignee') && isRenewalsReporting
            ? 'in_progress'
            : adjustedType === 'status' ? value : data.status
          : (adjustedType === 'assignee' || adjustedType === 'initial_application_assignee')
            ? 'in_progress'
            : adjustedType === 'status'
              ? value
              : data.status;

      let object = {
        [adjustedType]: formattedValue,
      };

      if (!(data.status === 'in_progress_deficiency' || data.status === 'ia_in_progress_deficiency' && (adjustedType === 'assignee' || adjustedType === 'initial_application_assignee'))) {
        object.status = computedStatus;
      }
      api(url, object, isLicenseSource ? 'PUT' : 'PATCH').then((data) => {
        if (!data.errors) {
          store.setCurrentLegislative(data, true)
          setLoading((prev) => ({
            ...prev,
            [type]: false,
          }))
          const objStatus = (mainActiveTab === 'ia_all'
            || mainActiveTab === 'ia_unassigned'
            || mainActiveTab === 'ia_in_progress'
            || mainActiveTab === 'ia_in_progress_ready_for_final_review'
            || mainActiveTab === 'ia_in_progress_submitted_to_govt'
            || mainActiveTab === 'ia_in_progress_deficiency') ? data.initial_application_status : data.status
          store.setSingleValue('newItemStatus', { id: id, status: objStatus })
          store.loadActivity(id, 1, null, isReportSource, isLicenseSource, isRenewalsReporting, reportId)
        }
      })
    }

    const onTaskOwnerChange = (value) => {
      const url = `/${isReportSource
        ? 'report_company'
        : isLicenseSource && !isRenewalsReporting ?
          'license_company'
          : isRenewalsReporting ? 'renewals_reporting'
            : 'legislative'}/${data.id}/change_tasks_assigner/`
      api(url, { new_assigner: value }, 'POST').then((data) => {
        if (!data.errors) {
          initialApplication
            ? setValue('initial_application_status', data.initial_application_status)
            : setValue('status', data.status)
          handleChange(value, 'assignee', false)
          message.success('The task assigner was changed successfully.”!')
          setVisibleTaskAssignModal(false)
        } else {
          message.error('Something went wrong!')
        }
      })
    }

    const onDatesSetUp = (value, type) => {
      const url = isReportSource
        ? `/report_company/${data.id}/details/`
        : initialApplication ? `/license_company/${data.id}/details/`
          : `/renewals_reporting/${data.id}/details/`
      const obj = type === 'deficiency'
        ? {
          in_progress_deficiency_date: moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD'),
          status: 'in_progress_deficiency',
        }
        : type === 'ia_deficiency'
          ? {
            in_progress_deficiency_date_ia: moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            initial_application_status: 'ia_in_progress_deficiency',
          }
          : type === 'ia_submitted_to_gov' ?
            isRenewalsReporting ?
              {
                closed_submitted_date: moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                status: 'in_progress_submitted_to_govt'
              }
              : {
                in_progress_submitted_date_ia: moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                initial_application_status: 'ia_in_progress_submitted_to_govt',
              }
            : {
              closed_submitted_date: moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD'),
              status: 'closed_submitted',
            }
      api(url, obj, 'PATCH').then((data) => {
        if (!data.errors) {
          store.setCurrentLegislative(data, true)
          store.loadActivity(id, 1, null, isReportSource, isLicenseSource, isRenewalsReporting, reportId)
          type === 'deficiency'
            ? store.setSingleValue('newItemStatus', { id: id, status: 'in_progress_deficiency' })
            : type === 'ia_deficiency'
              ? store.setSingleValue('newItemStatus', { id: id, status: 'ia_in_progress_deficiency' })
              : type === 'ia_submitted_to_gov'
                ? isRenewalsReporting
                  ? store.setSingleValue('newItemStatus', { id: id, status: 'in_progress_submitted_to_govt' })
                  : store.setSingleValue('newItemStatus', { id: id, status: 'ia_in_progress_submitted_to_govt' })
                : store.setSingleValue('newItemStatus', { id: id, status: 'closed_submitted' })
        }
      })
    }

    const moveLegislativeToArchive = () => {
      setArchiveButtonLoading(true)
      const obj = data?.status === 'closed' ? { status: 'unassigned' } : { status: 'closed' }
      api(`/${isReportSource ? 'report_company' : 'legislative'}/${data.id}/`, obj, 'PATCH').then((data) => {
        if (!data.errors) {
          store.setCurrentLegislative(data, true)
          store.loadLegislatives({ page: currentPage }, isReportSource)
          setArchiveButtonLoading(false)

        }
      })
      store.loadActivity(id, 1, null, isReportSource, isLicenseSource, isRenewalsReporting, reportId)
    }

    const postFile = (e, taskID, files, isTaskFile = false, status = 'working', isDeficiencyBlock, deficiencyId, immediateCompleteTaskProcess = false) => {
      setLoading((prev) => ({
        ...prev,
        file: true,
      }))

      ga4React?.gtag('event', 'file_upload')

      let formData = new FormData()

      isTaskFile
        ? formData.append('file', files[0] || e.target.files[0])
        : formData.append('file', files || e.target.files[0])

      isTaskFile
        ? formData.append('status', files[1])
        : formData.append('status', status)
      isDeficiencyBlock && formData.append('deficiency', deficiencyId)
      formData.append('legislative', data.id)
      if (taskID) formData.append('task', taskID) // Oleksandr, add optional task_id here

      api(isReportSource ? `/report_company/${data.id}/files/` :
        isLicenseSource ?
          params.tabName === 'renewals_reporting' || params.tabName === 'assigned' || params.tabName === 'closed' || params.tabName === 'all_renewals' ?
            `/renewals_reporting/${data.id}/files/`
            : `/license_company/${data.id}/files/`
          : '/legislative_file/', formData, 'POST', false, true).then((data) => {
            if (!data.errors) {
              if (!taskID && !isDeficiencyBlock) {
                store.setDocument(data)
                setSuccessFile(data.file_name)
              }

              if (taskID)
                getCurrentLegislative(store.currentLegislative.id, null, null, true, isReportSource, isLicenseSource)

              store.loadDocuments(id, 1, status, isReportSource, isLicenseSource, isRenewalsReporting)

              setLoading((prev) => ({
                ...prev,
                file: false,
              }))
            } else {
              message.error(getError(data))
            }
            reset({ ...getValues(), file: '' })
          })
    }

    const isAssigneeMember = (id) => {
      const result = isReportSource
        ? store.members?.find((item) => item.id === id)?.report_source_role === 'member'
        : isLicenseSource
          ? store.members?.find((item) => item.id === id)?.license_source_role === 'member'
          : store.members?.find((item) => item.id === id)?.member_type === 'member'
      return (
        result
      )
    }

    const getTasks = (isNeedHistory = false, isIaHistory = false) => {
      if (!params.id && !complianceItem && !isLicenseSource) return null;

      const endpoint = isReportSource
        ? `/report_company/${id}/tasks/`
        : isLicenseSource ?
          isRenewalsReporting && !isIaHistory ?
            data.type === 'renewal'
              ? `/renewals_reporting/${id}/tasks/`
              : `/report_company/${id}/tasks/`
            : isNeedHistory
              ? `/license_company/${isIaHistory ? data.license_company_id || id : id}/tasks/?for_history=true`
              : `/license_company/${id}/tasks/`
          : `/tasks/?legislative=${id}&page_size=100`;

      api(endpoint, {}, 'GET')
        .then((data) => {
          if (!data.errors) {
            const tasksData = isReportSource ? data : data.results;
            isNeedHistory ? setHistoryTasks(tasksData) : setTasks(tasksData);
            store.setSingleValue('legislativeTasks', tasksData);
          }
        })
        .catch((error) => {
          message.error('Error fetching tasks:', error);
        });
    };
    const getDeficiencyTasksBlocks = () => {
      const endpoint = isRenewalsReporting
        ? `/renewals_reporting/${id}/deficiency/tasks/`
        : `/license_company/${id}/deficiency/tasks/`
      isLicenseSource &&
        api(endpoint, {}, 'GET').then((data) => {
          if (!data.errors) {
            setDeficiencyTasks(data)
          }
        }).catch((error) => {
          message.error('Error fetching deficiency tasks:', error)
        })
    }

    const loadDetermination = (license_company_id, determination_id, onLoad, shouldUpdateList = false) => {
      const url = `/license_company/${license_company_id}/determination/${determination_id}/`;
      api(url, {}, 'GET').then((data) => {
        if (!data.errors) {
          store.setSingleValue('currentDetermination', data);
          setDeterminationSelected(true);
          onLoad && onLoad();
          shouldUpdateList && store.loadLegislatives({
            page: currentPage,
            page_size: 20,
          }, false, true);
        }
        else {
          message.error(getError(data))
        }
      });
    }

    const handleStarred = () => {
      const object = isLicenseSource
        ? { is_priority: !data?.is_priority }
        : { is_starred: !data?.is_starred }

      api(
        `${isLicenseSource && !isRenewalsReporting
          ? '/license_company/'
          : isLicenseSource && isRenewalsReporting && localStorage.getItem('reportInLs') !== 'true'
            ? '/renewals_reporting/' :
            isReportSource || localStorage.getItem('reportInLs') === 'true'
              ? '/report_company/' : '/legislative/'}${data?.id}/${isLicenseSource ? 'set_priority' : 'set_starred'}/`,
        object,
        'POST'
      ).then((data) => {
        if (!data.errors) {
          const mutatedLegislative = {
            ...store.currentLegislative,
            is_priority: data[id],
            is_starred: data[id],
          }
          store[isMyTasks ? 'loadTasks' : 'loadLegislatives']({
            page: currentPage,
            page_size: checkForTrial(store) ? 10 : 20,
          }, isReportSource && localStorage.getItem('reportInLs') !== 'true', isLicenseSource || localStorage.getItem('reportInLs') === 'true')
          store.setSingleValue(
            'currentLegislative',
            mutatedLegislative
          )
        }
      })
    }
    const handleDetailsState = (value) => {
      setDetailsState(value)
    }

    const getConsiderationData = () => {
      api(`/legislative/${store.currentLegislative.id}/considerations_info/`, {}, 'GET').then((data) => {
        setConsiderationData(data)
      })
    }

    const moveItemfromIaToPending = () => {
      const url = `/license_company/${store.currentLegislative.id}/remove_license_from_initial_application/`
      api(url, {}, 'POST').then((data) => {
        if (!data.errors) {
          store.loadLegislatives({
            page: currentPage,
            page_size: 20,
          }, false, true);
        }
        store.setSingleValue('currentLegislative', data)
        setVisibleConfirmPendingModal(false)
        message.success('License moved successfully')
      }
      )
    }

    const receiveGetReadyData = (isLicenseSource = false, isReportSource = false, isRenewalsReporting = false, determination_id, isNeedHistory = false, license_company_id) => {
      const url = isLicenseSource || isNeedHistory ?
        isRenewalsReporting && !isNeedHistory ? `/renewals_reporting/${store.currentLegislative.id}/get_ready_tasks_information/`
          : `/license_company/${isNeedHistory ? license_company_id : store.currentLegislative.id}/determination/${determination_id}/get_ready_tasks_information/`
        : isReportSource && `/report_company/${store.currentLegislative.id}/get_ready_tasks_information/`
      api(url, {}, 'GET').then((data) => {
        isNeedHistory ? setHistoryGetReadyData(data) :
          setGetReadyData(data)
      })
    }

    useEffect(() => {
      data?.has_held_history && getHeldHistory()
    }, [id])

    useEffect(() => {
      if (
        !store.lineOfBusinesses.length ||
        !store.jurs.length ||
        !store.topics.length
      ) {
        store.loadSelect('companyOptions')
      }

      // if (!store.statuses.length || store.report_source_statuses.length) {
      //   store.loadSelect('currentMemberOptions')
      // }
    }, [store])

    useEffect(() => {
      !isReportSource && !isLicenseSource && !considerationData && getConsiderationData()
    }, [id])

    useEffect(() => {
      (isReportSource || isLicenseSource) && determinationSelected === true
        && data?.status !== 'not_assessed' && data?.status !== 'na' && data?.status !== 'monitor'
        && receiveGetReadyData(isLicenseSource, isReportSource, isRenewalsReporting, store?.currentDetermination?.id)
    }, [id, store?.currentDetermination?.id])

    useEffect(() => {
      reset({
        ...data,
        assignee: isAssigneeMember(data.assignee) ? null : data.assignee,
      })
    }, [data])

    useEffect(() => {
      store.loadActivity(id, 1, null, isReportSource, isLicenseSource, isRenewalsReporting, reportId)
    }, [])

    useEffect(() => {
      if (params.subTab === 'comments') {
        if (tabsRef.current) {
          tabsRef.current.scrollIntoView({ behavior: 'smooth' });
          setActiveTab('comments')
        }
      }
    }, [])

    useEffect(() => {
      setTimeout(() => {
        setSuccessFile(null)
      }, 5000)
    }, [successFile])

    // useEffect(() => {
    //   (detailsState && activeTab !== 'details') && updateDetails()
    // }, [activeTab])

    useEffect(() => {
      if (isLicenseSource) {
        if (data?.selected_determination) {
          setDeterminationSelected(true)
          loadDetermination(data.id, data.selected_determination)
        } else if (data.determination) {
          setDeterminationSelected(true)
        }
        else { setDeterminationSelected(false) }
      }
    }, [data.id])

    useEffect(() => {
      const id = isRenewalsReporting ? data.license_company_id : data.id
      isLicenseSource && (data?.status !== 'not_assessed' && data?.status !== 'monitor' && data?.status !== 'na') && getAssessmentHistory(id)
    }, [])

    const dateMenu = (
      <>
        {((isReportSource && (mainActiveTab === 'main' || mainActiveTab === 'apcd')) || localStorage.getItem('reportInLs') === 'true') &&
          <Menu>
            {store?.currentItemLinkedReports?.length > 0
              ? (() => {
                let variableCount = 0;
                return store?.currentItemLinkedReports.map((item, index) => {
                  if (item.is_variable || item.is_upon_request) {
                    variableCount++;
                  }
                  const reportNumber = item.is_variable || item.is_upon_request ? variableCount : index + 1;
                  return (
                    <Menu.Item key={item.report_company_id} onClick={(e) => {
                      e.stopPropagation();
                    }}>
                      <Link to={`/report-source/closed/?report=${item.report_company_id}`} target="_blank">
                        {item.is_variable || item.is_upon_request
                          ? store?.currentItemLinkedReports.length === 1 ? 'Prior Report' : `Prior Report ${reportNumber}`
                          : <Moment format={'MM/DD/YYYY'}>{item.report_due_date}</Moment>}
                      </Link>
                    </Menu.Item>
                  );
                });
              })()
              : <Menu.Item >
                <div>No Prior Reports</div>
              </Menu.Item>
            }
          </Menu>
        }
      </>
    );

    const lsdateMenu = (type) => {
      return (
        <>
          <Menu>
            {store[type]?.length > 0
              ? (() => {
                let variableCount = 0;
                return store[type].map((item, index) => {
                  if (item.is_variable || item.is_upon_request) {
                    variableCount++;
                  }
                  const reportNumber = item.is_variable || item.is_upon_request ? variableCount : index + 1;
                  return (
                    <Menu.Item key={item.id} onClick={(e) => { }}>
                      <Link to={item.url} target="_blank">
                        {item.is_variable || item.is_upon_request
                          ? store[type].length === 1 ? 'Prior Report' : `Prior Report ${reportNumber}`
                          : <Moment format={'MM/DD/YYYY'}>{item.due_date}</Moment>}
                      </Link>
                    </Menu.Item>
                  );
                });
              })()
              : <Menu.Item >
                <div>{`No Prior ${type === 'currentItemLinkedReports' ? 'Reports' : 'Renewals'}`}</div>
              </Menu.Item>
            }
          </Menu>
        </>
      )
    };

    return (
      <Wrap name={'right-screen'}>
        {deleting && (
          <DeleteModal
            isReportSource={isReportSource}
            onConfirm={(isReportSource) => confirmDelete(isReportSource)}
            name={isReportSource
              ? data?.report?.report_title
              : data?.reference_number}
            onClose={() => setDeleting(!deleting)}
            loading={loading.deleting}
          />
        )}
        {visibleDeficiencyModal &&
          <DeficiencyModal
            editMode={deficiencyEditMode}
            defaultValue={data?.in_progress_deficiency_date || ''}
            type={'deficiency'}
            onSubmitDeficiency={onSubmitDeficiency}
            onForceClose={() => setVisibleDeficiencyModal(false)}
            isLicenseSource={isLicenseSource}
            isRenewalsReporting={isRenewalsReporting}
            licenseCompanyId={data.id}
            onClose={() => {
              if (isLicenseSource || isRenewalsReporting) {
                setDeficiencyEditMode(false);
                setVisibleDeficiencyModal(false);
              } else {
                setDeficiencyEditMode(false);
                setVisibleConfirmModal(true);
              }
            }}
            onSubmit={(value) => {
              onDatesSetUp(value, 'deficiency')
              setDeficiencyEditMode(false)
              setVisibleDeficiencyModal(false)
            }}
          />
        }

        {visibleHeldAddModal &&
          <HeldAdminItemAddModal
            id={data.id}
            heldType={heldType}
            setTrigger={setTrigger}
            trigger={trigger}
            onClose={() => {
              setHeldType(null)
              setVisibleHeldAddModal(false)
            }}
          />
        }
        {visibleNoLongerHeldModal &&
          <MoveLicenseModal
            moveFrom={'held'}
            id={data.id}
            currentPage={currentPage}
            heldType={heldType}
            onClose={() => {
              setVisibleNoLongerHeldModal(false)
            }}
          />
        }
        {visibleConfirmPendingModal && !(store.legislativeTasks?.find((item) => item.status !== 'complete')) &&
          <MoveLicenseModal
            moveFrom={'ia'}
            id={data.id}
            currentPage={currentPage}
            heldType={heldType}
            onClose={() => {
              setVisibleConfirmPendingModal(false)
            }}
          />
        }
        {visibleConfirmPendingModal && (store.legislativeTasks?.find((item) => item.status !== 'complete')) &&
          <CloseTasksModal
            onClose={() => setVisibleConfirmPendingModal(false)}
          />
        }
        {visibleAddCertificateModal &&
          <CertificateModal
            removeFile={deleteLicenseCertificate}
            initialCertificates={data?.held_certificates}
            id={data.id}
            getCurrentLegislative={() => getCurrentLegislative(store.currentLegislative.id, null, null, true, isReportSource, isLicenseSource)}
            onClose={() => {
              setVisibleAddCertificateModal(false)
            }}
          />
        }
        {visibleHeldModal &&
          <HeldModal
            id={data.id}
            editMode={heldModalEditMode}
            data={data}
            isPerpetual={data?.is_perpetual_license}
            currentPage={currentPage}
            filingTimePeriod={data?.filing_time_period || store?.currentDetermination?.license_determination?.filing_time_period || ''}
            mandatedExpirationDate={data?.state_mandated_expiration_date || store?.currentDetermination?.license_determination?.state_mandated_expiration_date || ''}
            onClose={() => {
              setHeldModalEditMode(false)
              setVisibleHeldModal(false)
            }}
          />
        }
        {determinationConfirmModal &&
          <DeterminationChangeConfirmModal
            onSubmit={() => {
              setDeterminationConfirmModal(false)
              setValue('selected_determination', tempDetermination)
              loadDetermination(data.id, tempDetermination, () => getCurrentLegislative(store.currentLegislative.id, null, null, true, isReportSource, isLicenseSource), true
              )
              setTempDetermination(null)
            }}
            onClose={() => setDeterminationConfirmModal(false)}
          />
        }
        {visibleClosedSubmittedModal &&
          <DeficiencyModal
            editMode={deficiencyEditMode}
            defaultValue={data?.closed_submitted_date || ''}
            type={'closed-submitted'}
            onClose={() => {
              setVisibleConfirmModal(true)
              setDeficiencyEditMode(false)
            }}
            onSubmit={(value) => {
              onDatesSetUp(value, 'closed-submitted')
              setVisibleClosedSubmittedModal(false)
              setDeficiencyEditMode(false)
            }}
          />
        }
        {visibleIaDeficiencyModal &&
          <DeficiencyModal
            licenseCompanyId={data.id}
            onForceClose={() => setVisibleIaDeficiencyModal(false)}
            onSubmitDeficiency={onSubmitDeficiency}
            isLicenseSource={isLicenseSource}
            isRenewalsReporting={isRenewalsReporting}
            editMode={deficiencyEditMode}
            defaultValue={data?.in_progress_deficiency_date_ia || ''}
            type={'ia_deficiency'}
            onClose={() => {
              if (isLicenseSource || isRenewalsReporting) {
                setDeficiencyEditMode(false);
                setVisibleIaDeficiencyModal(false);
              } else {
                setDeficiencyEditMode(false);
                setVisibleConfirmModal(true);
              }
            }}
            onSubmit={(value) => {
              onDatesSetUp(value, 'ia_deficiency')
              setDeficiencyEditMode(false)
              setVisibleIaDeficiencyModal(false)
            }}
          />
        }
        {visibleSubmittedToGovModal &&
          <DeficiencyModal
            editMode={deficiencyEditMode}
            defaultValue={data?.in_progress_submitted_date_ia || ''}
            type={'ia_submitted_to_gov'}
            onClose={() => {
              setDeficiencyEditMode(false)
              setVisibleConfirmModal(true)
            }}
            onSubmit={(value) => {
              onDatesSetUp(value, 'ia_submitted_to_gov')
              setDeficiencyEditMode(false)
              setVisibleSubmittedToGovModal(false)
            }}
          />
        }
        {visibleTaskCloseModal &&
          <CloseTasksModal
            onClose={() => setVisibleTaskCloseModal(false)}
          />
        }
        {
          visibleRenewalClosedModal &&
          <RenewalCloseModal
            id={data.id}
            currentPage={currentPage}
            status={renewalClosedStatus}
            setTempStatus={setRenewalClosedStatus}
            licenseEffectiveDate={data?.effective_date}
            licenseRenewalDate={data?.renewal_date}
            licenseNumber={data?.license_number}
            licenseId={data?.license_company_id}
            isPerpetual={data?.is_perpetual}
            filingTimePeriod={data?.filing_time_period || store?.currentDetermination?.license_determination?.filing_time_period || ''}
            mandatedExpirationDate={data?.state_mandated_expiration_date
              || store?.currentDetermination?.license_determination?.state_mandated_expiration_date
              || ''}
            onClose={() => setVisibleRenewalClosedModal(false)}
          />
        }
        {visibleConfirmModal &&
          <ConfirmModal
            onClose={() => setVisibleConfirmModal(false)}
            onSubmit={() => {
              setVisibleClosedSubmittedModal(false)
              setVisibleIaDeficiencyModal(false)
              setVisibleSubmittedToGovModal(false)
              setDeficiencyEditMode(false)
              setDeterminationConfirmModal(false)
              setVisibleDeficiencyModal(false)
              setVisibleConfirmModal(false)
            }}
          />}
        {visibleModal && (
          <Modal
            isReportSource={isReportSource}
            isLicenseSource={isLicenseSource}
            onClose={() => setVisibleModal(false)}
            onConfirm={() => setVisibleModal(false)}
          />
        )}
        {visibleOwnerModal && (
          <Modal
            title={`Need a ${isReportSource ? 'Report' : isLicenseSource ? pendingAssessments ? 'Assessment' : 'License' : 'Compliance'}  Owner to Close or Change Status`}
            text={`To close a ${isReportSource
              ? 'report'
              : isLicenseSource
                ? 'license'
                : 'compliance'} item or change status, a ${isReportSource
                  ? 'report'
                  : isLicenseSource
                    ? pendingAssessments
                      ? 'Assessment'
                      : 'license'
                    : 'compliance'} owner must be assigned.`}
            buttonText={`Add ${pendingAssessments ? 'an' : 'a'} ${isReportSource ? 'Report' : isLicenseSource ? pendingAssessments ? 'Assessment' : 'License' : 'Compliance'}  Owner`}
            showCancel={false}
            isReportSource={isReportSource}
            isLicenseSource={isLicenseSource}
            onClose={() => setVisibleOwnerModal(false)}
            onConfirm={() => setVisibleOwnerModal(false)}
          />
        )}
        {visibleTaskAssignModal && (
          <TasksReAssignModal assignee={data.assignee}
            isReportSource={isReportSource}
            isLicenseSource={isLicenseSource}
            itemId={data.id}
            onSubmit={(value) => onTaskOwnerChange(value)}
            onClose={() => setVisibleTaskAssignModal(false)}
          />
        )
        }
        <Reference showLinkedReports={isReportSource && mainActiveTab === 'main' && store.currentLegislative.report.created_by_company === null}>
          <RowWrap>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 30px 30px',
                gap: 10,
                alignItems: 'center',
              }}
            >
              {isLicenseSource ? data?.license?.license_title : isReportSource ? data?.report?.report_title : data.reference_number}
              {isReportSource ?
                showModeBlocks
                  ? <Circle style={{ backgroundColor: getReportStatusColor(data?.report?.status, data) }} />
                  : <Circle style={{ backgroundColor: `${data?.status === 'closed' ? 'var(--grey)' : 'var(--blue)'}`, }} />
                : showModeBlocks
                  ? isLicenseSource && data?.status === 'held'
                    ? null
                    : <Circle style={{ backgroundColor: getStatusColor(data?.status, data) }} />
                  : <Circle style={{ backgroundColor: `${data?.status === 'closed' ? 'var(--grey)' : 'var(--blue)'}`, }} />
              }
              {isReportSource && data?.status === 'in_progress_deficiency' && <img src={image} alt='warningBig' />}
            </div>
            {accessAllowed && trackingMode !== 'compliance_source_ultra_lite' && data.status !== 'held' && (
              <Button
                text={
                  <>
                    Priority{' '}
                    <img
                      src={isLicenseSource ?
                        data?.is_priority ? flag : flagGrey
                        : data?.is_starred ? flag : flagGrey}
                      alt='flag-icon'
                    />
                  </>
                }
                type={'white'}
                onClick={handleStarred}
              />
            )}
          </RowWrap>
          {isLicenseSource && data?.should_display_dropdown
            && (mainActiveTab !== 'renewals_reporting'
              && mainActiveTab !== 'assigned'
              && mainActiveTab !== 'all_renewals'
              && mainActiveTab !== 'closed'
            ) &&
            <Input
              styleContainer={{ marginTop: '1em', fontSize: 14 }}
              defaultValue={store?.currentDetermination?.id}
              style={{ width: 'fit-content', minWidth: '40%', fontSize: 14 }}
              type={'select'}
              control={control}
              name={'selected_determination'}
              placeholder={'Select Determination'}
              getPopupContainer={true}
              disabled={mainActiveTab === 'held' || params.tabName === 'pending_closed' || params.tabName === 'ia_closed'}
              allowClear={false}
              label={mainActiveTab === 'held'
                ? 'Change Determination'
                : data.selected_determination ? 'Select Determination'
                  : 'First Select Determination'}
              options={data.determinations.map((item) => ({ name: item.determination_type, id: item.id }))}
              handleChange={(value) => {
                if (data.selected_determination) {
                  setValue('selected_determination', data.selected_determination)
                  setDeterminationConfirmModal(true)
                  setTempDetermination(value)
                } else {
                  setDeterminationSelected(true)
                  loadDetermination(data.id, value)
                }
              }
              }
              smallLabel
              transparent
            />
          }
          {isReportSource && (mainActiveTab === 'main' || mainActiveTab === 'apcd') && store.currentLegislative.report.created_by_company === null &&
            <DocumentWrap
              style={{
                padding: '10px 15px',
                marginTop: 15,
                fontSize: 14,
                justifyContent: 'start',
                borderTop: '1px solid #C4CACC'
              }}
              grey={true}>
              <Dropdown overlay={dateMenu} >
                <div style={{ cursor: 'pointer' }}>
                  Prior Reports ▼
                </div>
              </Dropdown>
            </DocumentWrap>
          }
          <PriorsRow>
            {isLicenseSource || localStorage.getItem('reportInLs') === 'true'
              && isRenewalsReporting
              && store?.currentItemLinkedReports?.length > 0 &&
              <DocumentWrap
                style={{
                  padding: '10px 15px',
                  fontSize: 14,
                  justifyContent: 'start',
                }}
                grey={true}>
                {localStorage.getItem('reportInLs') === 'true' ?
                  <Dropdown overlay={dateMenu} >
                    <div style={{ cursor: 'pointer' }}>
                      Prior Reports ▼
                    </div>
                  </Dropdown>
                  : <Dropdown overlay={lsdateMenu('currentItemLinkedReports')} >
                    <div style={{ cursor: 'pointer' }}>
                      Prior Reports ▼
                    </div>
                  </Dropdown>
                }
              </DocumentWrap>
            }
            {isLicenseSource
              && isRenewalsReporting
              && store?.currentItemLinkedRenewals?.length > 0 &&
              <DocumentWrap
                style={{
                  padding: '10px 15px',
                  fontSize: 14,
                  justifyContent: 'start',
                }}
                grey={true}>
                <Dropdown overlay={lsdateMenu('currentItemLinkedRenewals')} >
                  <div style={{ cursor: 'pointer' }}>
                    Prior Renewals ▼
                  </div>
                </Dropdown>
              </DocumentWrap>
            }
          </PriorsRow>
        </Reference>
        {isLicenseSource && !determinationSelected &&
          <NotChoosen style={{ color: 'var(--blue)', fontFamily: 'var(--regular)' }}>
            <img src={licenseEmpty} alt='placeholder' />
            Select a license determination above to begin
          </NotChoosen>
        }
        {(isLicenseSource
          ? determinationSelected
            ? true
            : false
          : true) &&
          <Row style={{ marginTop: '1em' }}>
            {references?.map(
              ({ key, title, description, hide }) =>
                !hide && (
                  <Block key={key} style={{ maxWidth: 200 }}>
                    <Label>{title}</Label>
                    <Text dangerouslySetInnerHTML={{ __html: description }} />
                  </Block>
                )
            )}
          </Row>
        }

        {isReportSource && data.report && data?.report?.ties_to_license[0] !== 5 &&
          <Block style={{ marginTop: '1em', backgroundColor: 'var(--rose)', padding: 12 }}>
            <Label>{'Reporting applies if Reporting Entity holds the following license/registration in this jurisdiction:'}</Label>
            <div>
              {data?.report?.ties_to_license?.map((tie, index) => (
                <Text key={index}>
                  {getDescription(store?.legislative_options?.report_source_ties_to_license, tie)}
                </Text>
              ))}
            </div>
          </Block>
        }
        <div style={{ marginTop: 20 }}>
          <MoreInfo data={data}
            isReportSource={isReportSource}
            noHide={true}
            isLicenseSource={isLicenseSource}
            isRenewalsReporting={isRenewalsReporting}
            determinationSelected={determinationSelected}
          />
        </div>


        {isLicenseSource && determinationSelected && params.tabName === 'held' &&
          <Row style={{ marginTop: '1em' }}>
            {heldReferences?.map(
              ({ key, title, description, hide }) =>
                !hide && (
                  <Block key={key} style={{ maxWidth: 250 }}>
                    <Label>{title}</Label>
                    {title === 'View License Certificates:' ?
                      description
                      : <Text dangerouslySetInnerHTML={{ __html: description }} />
                    }
                    {title === 'License Number' &&
                      <ButtonOrange text={'Edit License Info'} key={'add_certificate'} onClick={() => {
                        setHeldModalEditMode(true)
                        setVisibleHeldModal(true)
                      }} />
                    }
                  </Block>
                )
            )}
          </Row>
        }
        {isLicenseSource && determinationSelected && params.tabName === 'held' &&
          <BlockWrap>
            <Tabs
              Reporting={false}
              tabs={heldAdminActionsTabs}
              onClick={(key) => {
                getCurrentLegislative(store.currentLegislative.id, null, null, true, isReportSource, isLicenseSource)
                setActiveAdminActionsTab(key)
                setTrigger(!trigger)
              }}
              active={activeAdminActionsTab}
            />

            {showHeldMaterialsTabs({
              data, setHeldType, setVisibleHeldAddModal, trigger, setTrigger
            })[activeAdminActionsTab].render()
            }
          </BlockWrap>
        }
        {isLicenseSource
          && determinationSelected
          && (data?.status === 'monitor' || data?.status === 'na' || data?.status === 'not_assessed')
          && params.tabName !== 'initial_application'
          && params.tabName !== 'assigned'
          && params.tabName !== 'all_renewals'
          && store?.currentDetermination &&
          <>
            <LicenseInfoBlock
              title={'State Contact'}
              description={(store?.currentDetermination?.state_contact[0]?.description && store?.currentDetermination?.state_contact[0]?.description) || ''}
            />
            <LicenseInfoBlock
              title={'Citations'}
              description={(store?.currentDetermination?.citations[0]?.description && store?.currentDetermination?.citations[0]?.description) || ''}
            />
            <LicenseInfoBlock
              title={'Definitions'}
              description={store?.currentDetermination?.definitions || data?.definitions || ''}
            />
            <LicenseInfoBlock
              title={'Penalties'}
              description={(store?.currentDetermination?.penalties[0]?.description && store?.currentDetermination?.penalties[0]?.description) || ''}
            />
            <LicenseInfoBlock
              title={'Tips'}
              description={(store?.currentDetermination?.tips[0]?.description && store?.currentDetermination?.tips[0]?.description) || ''}
            />
          </>
        }

        {isLicenseSource && determinationSelected
          && (data?.status === 'monitor' || data?.status === 'na' || data?.status === 'not_assessed')
          &&
          <Read
            style={{ backgroundColor: 'var(--green)', marginTop: '1em' }}
            icon={pdf}
            href={store?.currentDetermination?.license_link}
            as={'a'}
            target={'_blank'}
          >
            <img src={pdf} alt='pdf-icon' />
            View License Web Page
          </Read>
        }

        {
          isLicenseSource && determinationSelected && !isRenewalsReporting
          && <InterLinks data={store?.currentDetermination} store={store} />
        }

        {
          isLicenseSource && isRenewalsReporting
          && <InterLinks data={data} store={store} />
        }

        {
          isLicenseSource
          && (determinationSelected || isRenewalsReporting)
          && (data?.status === 'needed' || isRenewalsReporting) && params.tabName !== 'pending_closed' &&
          <>
            <Tabs
              Reporting={false}
              tabs={
                params.tabName === 'held' ?
                  heldmaterialsTabs
                  : isRenewalsReporting
                    ? renewalsTabs
                    : needmaterialsTabs
              }
              onClick={(key) => {
                getCurrentLegislative(store.currentLegislative.id, null, null, true, isReportSource, isLicenseSource)
                setActiveLsMaterialsTab(key)
              }}
              active={activeLsMaterialsTab}
            />

            {showLsMaterialsTabs({
              data, isMyTasks, getReadyData, receiveGetReadyData, handleOpen, setAssigneNew, showModeBlocks, isLicenseSource, determinationId: data?.selected_determination
            })[activeLsMaterialsTab].render()
            }
          </>
        }

        {
          accessAllowed && !isLicenseSource && trackingMode !== 'compliance_source_ultra_lite' && (!!data.report_interlinking_items.length || !!data.compliance_interlinking_items.length)
          && <InterLinks data={data} store={store} />
        }
        {
          isReportSource && data.is_new_requirement &&
          <NewRequirementBlock text={data.new_requirement_text} updateDate={data.requirement_text_updated_date} />
        }
        {
          isLicenseSource ? null : isReportSource ?
            data.internal_due_date &&
            <InternalDueDateBlock>
              <strong>Internal Due Date:</strong>  {moment(data?.internal_due_date).format('MM/DD/YYYY')}
            </InternalDueDateBlock>
            : data.primary_due_date &&
            <InternalDueDateBlock>
              <strong>Internal Due Date:</strong>  {moment(data?.primary_due_date).format('MM/DD/YYYY')}
            </InternalDueDateBlock>
        }

        {
          data.notes && !isLicenseSource && (trackingMode !== 'compliance_source_ultra_lite') && (
            <Block style={{ marginTop: '1em' }}>
              <Label>Notes:</Label>
              <Text
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(data.notes),
                }}
              />
            </Block>
          )
        }
        {
          !isReportSource && !isLicenseSource && data.title
          && <Block style={{ marginTop: '1em' }}>
            <Label>{'Title:'}</Label>
            <Text
              dangerouslySetInnerHTML={{
                __html: converter.makeHtml(data.title),
              }}
            />
          </Block>
        }
        {
          !isReportSource && !isLicenseSource && data.summary
          && <Block style={{ marginTop: '1em' }}>
            <Label>{'Summary:'}</Label>
            <Text
              dangerouslySetInnerHTML={{
                __html: isMyTasks
                  ? highlightSearchedWords(data.summary, store?.searchableObjectTasks?.search)
                  : highlightSearchedWords(data.summary, store?.searchableObject?.search),
              }}
            />
          </Block>
        }
        {
          !isReportSource && !isLicenseSource && data.tips && data.tips.trim().length > 0
          && <Block style={{ marginTop: '1em', backgroundColor: '#DBE9F1', padding: 12 }}>
            <Label>{'Tips:'}</Label>
            <Text dangerouslySetInnerHTML={{
              __html: data.tips,
            }} />
            <DisclaimerBlock>
              This information is informational only and is not a substitute for an attorney or law firm.
              Determining the applicable line of business and other applicability for a report/requirement often is subject to interpretation.
              PBMSource has made efforts to assess the appropriate lines of business and applicability of these requirements
              (which are subject to change as additional information becomes available).
              This information is provided as guidance only, and you are encouraged to do your own research and assessments and/or contact an attorney or the applicable government agency.
              If you question or conclude a different line of business(es) or applicability,
              please let us know so that we can consider updates to this information. Contact us at 888-684-1391 or <a href='mailto:contact@pbmsource.com'>contact@pbmsource.com</a>.
            </DisclaimerBlock>
          </Block>
        }

        {
          isLicenseSource
            ? null
            : !isReportSource ?
              showModeBlocks ?
                <Read
                  style={{ backgroundColor: 'var(--green)', marginTop: '1em' }}
                  icon={pdf}
                  href={data?.reference_document}
                  as={'a'}
                  target={'_blank'}
                >
                  <img src={pdf} alt='pdf-icon' />
                  View Source
                </Read>
                :
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Read
                    style={{ backgroundColor: 'var(--green)', marginTop: '1em' }}
                    icon={pdf}
                    href={data?.reference_document}
                    as={'a'}
                    target={'_blank'}
                  >
                    <img src={pdf} alt='pdf-icon' />
                    View Source
                  </Read>
                  <Button
                    style={{ backgroundColor: 'var(--blue)', marginTop: '1em' }}
                    onClick={moveLegislativeToArchive}
                    loading={archiveButtonLoading}
                    text={data?.status === 'closed' ? 'To Tracking' : 'Archive'}
                  />
                </div>
              : null
        }
        {isReportSource && !isLicenseSource &&
          <>
            <Tabs
              Reporting={false}
              tabs={data?.report?.notice_of_change ? reportMaterialsTabsNotice : reportMaterialsTabs}
              onClick={(key) => {
                // getCurrentLegislative(store.currentLegislative.id, null, null, true, isReportSource)
                setActiveMaterialsTab(key)
              }}
              active={activeMaterialsTab}
            />
            {showReportMaterialsTabs({
              data, isMyTasks, getReadyData, receiveGetReadyData, handleOpen, setAssigneNew, showModeBlocks, determinationId: store?.currentDetermination?.id
            })[activeMaterialsTab].render()
            }
          </>
        }
        {
          isReportSource && !showModeBlocks && !isLicenseSource &&
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Button
              style={{ backgroundColor: 'var(--blue)', marginTop: '1em' }}
              onClick={moveLegislativeToArchive}
              loading={archiveButtonLoading}
              text={data?.status === 'closed' ? 'To Report' : 'Archive'}
            />
          </div>
        }
        {
          <Inputs name={'info-inputs'}>
            {isReportSource && showModeBlocks ?
              reportInfoInputs(store, data).map((item) =>
                item.role.includes(localStorage.getItem('reportInLs') === 'true'
                  ? store.user[0]?.license_source_role
                  : store.user[0]?.report_source_role) ? (
                  item.type === 'date' ? (
                    <CalendarCustom
                      handleChange={(value) =>
                        handleChange(value, 'internal_due_date')
                      }
                      incomeValue={data?.internal_due_date}
                      loading={loading.primary_due_date}
                      styleDropdown={{ left: 'auto', right: 0, top: 50 }}
                      placeholder={'Select Date'}
                      label={'Internal Due Date'}
                      redCircleDate={data?.internal_due_date}

                    />
                  ) : (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Input
                        type={'select'}
                        control={control}
                        name={item.name}
                        placeholder={item.placeholder}
                        allowClear={false}
                        label={item.label}
                        options={item.options}
                        customOptions={item.customOptions}
                        selectVars={item.selectVars}
                        handleChange={(value) => handleChange(value, item.name)}
                        getPopupContainer={true}
                        smallLabel
                        transparent
                        showSearch={item.name === 'assignee'}
                      />
                      {item.name === 'status' && data.in_progress_deficiency_date && data.status === 'in_progress_deficiency' &&
                        <div style={{
                          fontSize: 12,
                          fontFamily: 'var(--semiBold)',
                          color: 'var(--grey)',
                          marginLeft: 10,
                        }}>
                          Deficiency Due Date: {moment(data.in_progress_deficiency_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                          <ButtonOrange
                            style={{ fontSize: 12, marginLeft: 5 }}
                            text={'Edit'}
                            onClick={() => {
                              setDeficiencyEditMode(true)
                              setVisibleDeficiencyModal(true)
                            }}
                          />
                        </div>
                      }
                      {item.name === 'status' && data.closed_submitted_date && data.status === 'closed_submitted' &&
                        <div style={{
                          fontSize: 12,
                          fontFamily: 'var(--semiBold)',
                          color: 'var(--grey)',
                          marginLeft: 10,
                        }}>
                          Sumbited Date: {moment(data.closed_submitted_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                          <ButtonOrange
                            style={{ fontSize: 12, marginLeft: 5 }}
                            text={'Edit'}
                            onClick={() => {
                              setDeficiencyEditMode(true)
                              setVisibleClosedSubmittedModal(true)
                            }}
                          />
                        </div>
                      }
                    </div>
                  )
                ) : (
                  <div>
                    <Label smallLables>{item.label}</Label>
                    <span style={{ fontSize: 14 }}>{item.value}</span>
                  </div>
                )
              )
              : isLicenseSource ?
                (determinationSelected || isRenewalsReporting) && data?.status !== 'held' && params.tabName !== 'pending_closed' ?
                  lsInfoInputs(store, data).map((item) =>
                    item.role.includes(store.user[0]?.license_source_role) ? (
                      item.type === 'date' ? (
                        <CalendarCustom
                          handleChange={(value) =>
                            data?.status === 'needed' ?
                              handleChange(value, 'initial_application_internal_due_date')
                              : handleChange(value, 'internal_due_date')
                          }
                          incomeValue={data?.status === 'needed' ? data?.initial_application_internal_due_date : data?.internal_due_date}
                          loading={loading.primary_due_date}
                          styleDropdown={{ left: 'auto', right: 0, top: 50 }}
                          placeholder={'Select Date'}
                          label={'Internal Due Date'}
                          redCircleDate={data?.internal_due_date}
                          disabled={item.disabled}
                        />
                      ) : (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <Input
                            type={'select'}
                            control={control}
                            name={item.name}
                            placeholder={item.placeholder}
                            allowClear={false}
                            label={item.label}
                            options={item.options}
                            customOptions={item.customOptions}
                            selectVars={item.selectVars}
                            disabled={item.disabled}
                            handleChange={(value) => handleChange(value, item.name)}
                            getPopupContainer={true}
                            smallLabel
                            transparent
                            showSearch={item.name === 'assignee'}
                          />
                          {item.name === 'status' && data.in_progress_deficiency_date && data.status === 'in_progress_deficiency' &&
                            <div style={{
                              fontSize: 12,
                              fontFamily: 'var(--semiBold)',
                              color: 'var(--grey)',
                              marginLeft: 10,
                            }}>
                              Deficiency Due Date: {moment(data.in_progress_deficiency_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                              {!isRenewalsReporting &&
                                <ButtonOrange
                                  style={{ fontSize: 12, marginLeft: 5 }}
                                  text={'Edit'}
                                  onClick={() => {
                                    setDeficiencyEditMode(true)
                                    setVisibleDeficiencyModal(true)
                                  }}
                                />
                              }
                            </div>
                          }
                          {(item.name === 'initial_application_status' || (isRenewalsReporting && item.name === 'staus')) && data.in_progress_deficiency_date_ia && data.initial_application_status === 'ia_in_progress_deficiency' &&
                            <div style={{
                              fontSize: 12,
                              fontFamily: 'var(--semiBold)',
                              color: 'var(--grey)',
                              marginLeft: 10,
                            }}>
                              Deficiency Due Date: {moment(data.in_progress_deficiency_date_ia, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                              {/* <ButtonOrange
                                style={{ fontSize: 12, marginLeft: 5 }}
                                text={'Edit'}
                                onClick={() => {
                                  setDeficiencyEditMode(true)
                                  setVisibleIaDeficiencyModal(true)
                                }}
                              /> */}
                            </div>
                          }
                          {(item.name === 'initial_application_status' || (isRenewalsReporting && item.name === 'staus')) && data.in_progress_submitted_date_ia && data.initial_application_status === 'ia_in_progress_submitted_to_govt' &&
                            <div style={{
                              fontSize: 12,
                              fontFamily: 'var(--semiBold)',
                              color: 'var(--grey)',
                              marginLeft: 10,
                            }}>
                              Sumbited Date: {moment(data.in_progress_submitted_date_ia, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                              <ButtonOrange
                                style={{ fontSize: 12, marginLeft: 5 }}
                                text={'Edit'}
                                onClick={() => {
                                  setDeficiencyEditMode(true)
                                  setVisibleSubmittedToGovModal(true)
                                }}
                              />
                            </div>
                          }
                          {item.name === 'status' && data.closed_submitted_date && data.status === 'in_progress_submitted_to_govt' &&
                            <div style={{
                              fontSize: 12,
                              fontFamily: 'var(--semiBold)',
                              color: 'var(--grey)',
                              marginLeft: 10,
                            }}>
                              Sumbited Date: {moment(data.closed_submitted_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                              <ButtonOrange
                                style={{ fontSize: 12, marginLeft: 5 }}
                                text={'Edit'}
                                onClick={() => {
                                  setDeficiencyEditMode(true)
                                  setVisibleSubmittedToGovModal(true)
                                }}
                              />
                            </div>
                          }
                        </div>
                      )
                    ) : (
                      <div>
                        <Label smallLables>{item.label}</Label>
                        <Label style={{ fontSize: 14, fontFamily: 'var(--regular)' }}>{item.value}</Label>
                      </div>
                    )
                  ) : null
                : trackingMode === "compliance_source" && infoInputs(store, data).map((item) =>
                  item.role.includes(store.user[0]?.member_type) ? (
                    item.type === 'date' ? (
                      <CalendarCustom
                        handleChange={(value) =>
                          handleChange(value, 'primary_due_date')
                        }
                        incomeValue={data?.primary_due_date}
                        loading={loading.primary_due_date}
                        styleDropdown={{ left: 'auto', right: 0, top: 50 }}
                        placeholder={'Select Date'}
                        label={'Internal Due Date'}
                        redCircleDate={data?.compliance_date}
                      />
                    ) : (
                      <Input
                        type={'select'}
                        control={control}
                        name={item.name}
                        placeholder={item.placeholder}
                        allowClear={false}
                        label={item.label}
                        options={item.options}
                        customOptions={item.customOptions}
                        selectVars={item.selectVars}
                        handleChange={(value) => handleChange(value, item.name)}
                        smallLabel
                        getPopupContainer={true}
                        transparent
                        showSearch={item.name === 'assignee'}
                      />
                    )
                  ) : (
                    <div>
                      <Label smallLables>{item.label}</Label>
                      <span style={{ fontSize: 14 }}>{item.value}</span>
                    </div>
                  )
                )}
          </Inputs>
        }
        {isLicenseSource ?
          (determinationSelected || isRenewalsReporting) && data?.status !== 'held' && params.tabName !== 'pending_closed' &&
          <Tasks
            data={data}
            onEditDeficiency={onDeficientyEditMode}
            getCurrentLegislative={getCurrentLegislative}
            postFile={postFile}
            isMyTasks={isMyTasks}
            currentPage={currentPage}
            loadingFile={loading.file}
            complianceItem={complianceItem}
            tasks={tasks}
            getTasks={getTasks}
            getDeficiencyTasksBlocks={getDeficiencyTasksBlocks}
            deficiencyTasksBlocks={deficiencyTasks}
            activeTab={mainActiveTab}
            isReportSource={isReportSource}
            setActiveTab={() => setActiveTab(isLicenseSource ?
              (data.status === 'not_assessed' || data.status === 'monitor' || data.status === 'na')
                ? 'assessment'
                : 'details_pending'
              : 'details')}
            visible={visible}
            handleClose={handleClose}
            handleOpen={handleOpen}
            assigneNew={assigneNew}
            setAssigneNew={setAssigneNew}
            getConsiderationData={getConsiderationData}
            receiveGetReadyData={receiveGetReadyData}
            isLicenseSource={isLicenseSource}
            initialApplication={initialApplication}
          />
          :
          showModeBlocks &&
          <Tasks
            data={data}
            onEditDeficiency={onDeficientyEditMode}
            getCurrentLegislative={getCurrentLegislative}
            postFile={postFile}
            isMyTasks={isMyTasks}
            currentPage={currentPage}
            loadingFile={loading.file}
            complianceItem={complianceItem}
            tasks={tasks}
            getTasks={getTasks}
            getDeficiencyTasksBlocks={getDeficiencyTasksBlocks}
            deficiencyTasksBlocks={deficiencyTasks}
            activeTab={mainActiveTab}
            isReportSource={isReportSource}
            setActiveTab={() => setActiveTab('details')}
            visible={visible}
            handleClose={handleClose}
            handleOpen={handleOpen}
            assigneNew={assigneNew}
            setAssigneNew={setAssigneNew}
            getConsiderationData={getConsiderationData}
            receiveGetReadyData={receiveGetReadyData}
            isLicenseSource={isLicenseSource}
            initialApplication={initialApplication}
          />
        }
        {isReportSource && showModeBlocks && localStorage.getItem('reportInLs') !== 'true' &&
          <ImpactedClients id={store.currentLegislative.id} isReportSource={true} disabled={!isAdminOrReportOwner} />
        }
        {((isLicenseSource && (data.status === 'needed' && params.tabName !== 'pending_closed') && determinationSelected) ||
          (isRenewalsReporting || localStorage.getItem('reportInLs') === 'true')) &&
          <ImpactedClients
            id={store.currentLegislative.id}
            isReportSource={isReportSource}
            isLicenseSource={isLicenseSource}
            isRenewalsReporting={isRenewalsReporting}
            disabled={!isAdminorLicenseOwner}
          />
        }
        {
          showtabs && data?.status !== 'held' && params.tabName !== 'pending_closed' &&
          <Tabs
            Reporting={false}
            tabs={getTabs({ isReportSource, isLicenseSource, isRenewalsReporting, data })}
            onClick={(key) => setActiveTab(key)}
            documentTabs={true}
            active={activeTab}
          />
        }
        {
          showtabs && data?.status !== 'held' && params.tabName !== 'pending_closed' && showTabs({
            data,
            control,
            postFile,
            loading,
            labelStyle,
            successFile,
            getTasks,
            isReportSource,
            isLicenseSource,
            isRenewalsReporting,
            detailsState,
            handleDetailsState,
            visible,
            handleClose,
            handleOpen,
            assigneNew,
            setAssigneNew,
            getConsiderationData,
            considerationData,
          })[activeTab].render()
        }
        {isLicenseSource && params.tabName === 'pending_closed' && assessmentHistory &&
          <>
            <HistoryTip> Information in this section is historical and not editable</HistoryTip>
            <LicenseInfoBlock
              title={'State Contact'}
              description={(assessmentHistory?.state_contact[0]?.description && assessmentHistory?.state_contact[0]?.description) || ''}
            />
            <LicenseInfoBlock
              title={'Citations'}
              description={(assessmentHistory?.citations[0]?.description && assessmentHistory?.citations[0]?.description) || ''}
            />
            <LicenseInfoBlock
              title={'Definitions'}
              description={assessmentHistory?.definitions || data?.definitions || ''}
            />
            <LicenseInfoBlock
              title={'Penalties'}
              description={(assessmentHistory?.penalties[0]?.description && assessmentHistory?.penalties[0]?.description) || ''}
            />
            <LicenseInfoBlock
              title={'Tips'}
              description={(assessmentHistory?.tips[0]?.description && assessmentHistory?.tips[0]?.description) || ''}
            />
            <Read
              style={{ backgroundColor: 'var(--green)', marginTop: '1em' }}
              icon={pdf}
              href={assessmentHistory.license_link}
              as={'a'}
              target={'_blank'}
            >
              <img src={pdf} alt='pdf-icon' />
              View License Web Page
            </Read>
            <Inputs name={'info-inputs'}>
              {lsInfoInputs(store, data, false, false, true).map((item) =>
                item.role.includes(store.user[0]?.license_source_role) ? (
                  item.type === 'date' ? (
                    <CalendarCustom
                      handleChange={(value) =>
                        handleChange(value, 'internal_due_date')
                      }
                      incomeValue={data?.internal_due_date}
                      loading={loading.primary_due_date}
                      styleDropdown={{ left: 'auto', right: 0, top: 50 }}
                      placeholder={'Select Date'}
                      label={'Internal Due Date'}
                      redCircleDate={data?.internal_due_date}
                      disabled={item.disabled}
                    />
                  ) : (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Input
                        type={'select'}
                        control={control}
                        name={item.name}
                        placeholder={item.placeholder}
                        allowClear={false}
                        label={item.label}
                        options={item.options}
                        customOptions={item.customOptions}
                        selectVars={item.selectVars}
                        disabled={item.disabled}
                        handleChange={(value) => handleChange(value, item.name)}
                        getPopupContainer={true}
                        smallLabel
                        transparent
                        showSearch={item.name === 'assignee'}
                      />
                      {item.name === 'status' && data.in_progress_deficiency_date && data.status === 'in_progress_deficiency' &&
                        <div style={{
                          fontSize: 12,
                          fontFamily: 'var(--semiBold)',
                          color: 'var(--grey)',
                          marginLeft: 10,
                        }}>
                          Deficiency Due Date {moment(data.in_progress_deficiency_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                          {/* <ButtonOrange
                                  style={{ fontSize: 12, marginLeft: 5 }}
                                  text={'Edit'}
                                  onClick={() => {
                                    setDeficiencyEditMode(true)
                                    setVisibleDeficiencyModal(true)
                                  }}
                                /> */}
                        </div>
                      }
                      {item.name === 'status' && data.closed_submitted_date && data.status === 'closed_submitted' &&
                        <div style={{
                          fontSize: 12,
                          fontFamily: 'var(--semiBold)',
                          color: 'var(--grey)',
                          marginLeft: 10,
                        }}>
                          Sumbited Date: {moment(data.closed_submitted_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                          <ButtonOrange
                            style={{ fontSize: 12, marginLeft: 5 }}
                            text={'Edit'}
                            onClick={() => {
                              setDeficiencyEditMode(true)
                              setVisibleClosedSubmittedModal(true)
                            }}
                          />
                        </div>
                      }
                    </div>
                  )
                ) : (
                  <div>
                    <Label smallLables>{item.label}</Label>
                    <span style={{ fontSize: 14 }}>{item.value}</span>
                  </div>
                )
              )}
            </Inputs>
            <Tasks
              data={data}
              onEditDeficiency={onDeficientyEditMode}
              getCurrentLegislative={getCurrentLegislative}
              deficiencyTasksBlocks={deficiencyTasks}
              postFile={postFile}
              isMyTasks={isMyTasks}
              currentPage={currentPage}
              loadingFile={loading.file}
              complianceItem={complianceItem}
              tasks={assessmentHistory.tasks}
              getTasks={getTasks}
              getDeficiencyTasksBlocks={getDeficiencyTasksBlocks}
              activeTab={mainActiveTab}
              isReportSource={isReportSource}
              setActiveTab={() => setActiveTab('assessment')}
              visible={visibleDisabledTaskModal}
              handleClose={openDisabledTaskModal}
              handleOpen={closeDisabledTaskModal}
              assigneNew={assigneNew}
              setAssigneNew={setAssigneNew}
              getConsiderationData={getConsiderationData}
              receiveGetReadyData={receiveGetReadyData}
              isLicenseSource={isLicenseSource}
              initialApplication={initialApplication}
              disabled={true}
            />
            {showtabs
              &&
              <Tabs
                Reporting={false}
                tabs={[
                  { key: 'assessment_history', title: 'Notes' },
                  { key: 'comments_history', title: '@Messages' },
                  { key: 'impacted_clients_history', title: 'Impacted Clients' },
                  { key: 'assessment_activity', title: 'Activity' },
                ]}
                onClick={(key) => { setActiveAssessmentHistoryTab(key) }}
                documentTabs={true}
                active={activeAssessmentHistoryTab}
              />
            }
            {
              showtabs
              && showTabs({
                data,
                control,
                postFile,
                loading,
                labelStyle,
                successFile,
                getTasks,
                isReportSource,
                isLicenseSource,
                isRenewalsReporting,
                detailsState,
                handleDetailsState,
                visible,
                handleClose,
                handleOpen,
                assigneNew,
                setAssigneNew,
                getConsiderationData,
                considerationData,
              })[activeAssessmentHistoryTab].render()
            }
          </>}
        {(isLicenseSource && params.tabName === "ia_closed") &&
          <>
            <HistoryTip> Information in this section is historical and not editable</HistoryTip>
            <Tabs
              Reporting={false}
              tabs={heldmaterialsTabs}
              onClick={(key) => {
                getCurrentLegislative(store.currentLegislative.id, null, null, true, isReportSource, isLicenseSource)
                setActiveIaHistoryTab(key)
              }}
              active={activeIaHistoryTab}
            />
            {showLsMaterialsTabs({
              data,
              isMyTasks,
              getReadyData,
              historyGetReadyData,
              receiveGetReadyData,
              handleOpen,
              setAssigneNew,
              showModeBlocks,
              isLicenseSource,
              determinationId: data?.selected_determination || data.determination,
              isNeedHistory: true,
              license_company_id: data?.license_company_id
            })[activeIaHistoryTab].render()
            }
            {(data?.status === 'held' || isRenewalsReporting) &&
              <Inputs name={'info-inputs'}>
                {lsInfoInputs(store, data, true, true).map((item) =>
                  item.role.includes(store.user[0]?.license_source_role) ? (
                    item.type === 'date' ? (
                      <CalendarCustom
                        handleChange={(value) =>
                          handleChange(value, 'internal_due_date')
                        }
                        incomeValue={data?.internal_due_date}
                        loading={loading.primary_due_date}
                        styleDropdown={{ left: 'auto', right: 0, top: 50 }}
                        placeholder={'Select Date'}
                        label={'Internal Due Date'}
                        redCircleDate={data?.internal_due_date}
                        disabled={item.disabled}
                      />
                    ) : (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Input
                          type={'select'}
                          control={control}
                          name={item.name}
                          placeholder={item.placeholder}
                          allowClear={false}
                          label={item.label}
                          options={item.options}
                          customOptions={item.customOptions}
                          selectVars={item.selectVars}
                          disabled={item.disabled}
                          handleChange={(value) => handleChange(value, item.name)}
                          getPopupContainer={true}
                          smallLabel
                          transparent
                          showSearch={item.name === 'assignee'}
                        />
                        {item.name === 'status' && data.in_progress_deficiency_date && data.status === 'in_progress_deficiency' &&
                          <div style={{
                            fontSize: 12,
                            fontFamily: 'var(--semiBold)',
                            color: 'var(--grey)',
                            marginLeft: 10,
                          }}>
                            Deficiency Due Date {moment(data.in_progress_deficiency_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                            {/* <ButtonOrange
                                  style={{ fontSize: 12, marginLeft: 5 }}
                                  text={'Edit'}
                                  onClick={() => {
                                    setDeficiencyEditMode(true)
                                    setVisibleDeficiencyModal(true)
                                  }}
                                /> */}
                          </div>
                        }
                        {item.name === 'status' && data.closed_submitted_date && data.status === 'closed_submitted' &&
                          <div style={{
                            fontSize: 12,
                            fontFamily: 'var(--semiBold)',
                            color: 'var(--grey)',
                            marginLeft: 10,
                          }}>
                            Sumbited Date: {moment(data.closed_submitted_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                            <ButtonOrange
                              style={{ fontSize: 12, marginLeft: 5 }}
                              text={'Edit'}
                              onClick={() => {
                                setDeficiencyEditMode(true)
                                setVisibleClosedSubmittedModal(true)
                              }}
                            />
                          </div>
                        }
                      </div>
                    )
                  ) : (
                    <div>
                      <Label smallLables>{item.label}</Label>
                      <span style={{ fontSize: 14 }}>{item.value}</span>
                    </div>
                  )
                )}
              </Inputs>
            }

            <Tasks
              data={data}
              onEditDeficiency={onDeficientyEditMode}
              getCurrentLegislative={getCurrentLegislative}
              deficiencyTasksBlocks={deficiencyTasks}
              postFile={postFile}
              isMyTasks={isMyTasks}
              currentPage={currentPage}
              loadingFile={loading.file}
              complianceItem={complianceItem}
              tasks={historyTasks}
              getTasks={() => getTasks(true, true)}
              getDeficiencyTasksBlocks={getDeficiencyTasksBlocks}
              activeTab={mainActiveTab}
              isReportSource={isReportSource}
              setActiveTab={() => setActiveTab('details')}
              visible={visibleDisabledTaskModal}
              handleClose={openDisabledTaskModal}
              handleOpen={closeDisabledTaskModal}
              assigneNew={assigneNew}
              setAssigneNew={setAssigneNew}
              getConsiderationData={getConsiderationData}
              receiveGetReadyData={receiveGetReadyData}
              isLicenseSource={isLicenseSource}
              initialApplication={initialApplication}
              isIaHistory={isRenewalsReporting}
              disabled={true}
            />
            <ImpactedClients
              isNeedHistory={true}
              id={isRenewalsReporting ? data?.license_company_id : store.currentLegislative.id}
              isReportSource={isReportSource}
              isLicenseSource={isLicenseSource}
              isRenewalsReporting={isRenewalsReporting}
              disabled={true}
            />
            {
              showtabs &&
              <Tabs
                Reporting={false}
                tabs={[
                  { key: 'details_pending', title: 'Notes', },
                  { key: 'comments', title: '@Messages', },
                  { key: 'documents_working', title: 'Working' },
                  { key: 'documents_finalized', title: 'Finalized' },
                  { key: 'documents_submitted', title: 'Submitted' },
                  { key: 'documents_deficiency', title: 'Deficiency' },
                  { key: 'documents_other', title: 'Other' },
                  { key: 'activity', title: 'Activity', },
                ]
                }
                onClick={(key) => setActiveNeedHistoryTab(key)}
                documentTabs={true}
                active={activeNeedHistoryTab}
              />
            }
            {
              showtabs && showTabs({
                data,
                control,
                postFile,
                loading,
                labelStyle,
                successFile,
                getTasks,
                isReportSource,
                isLicenseSource,
                isRenewalsReporting,
                detailsState,
                handleDetailsState,
                visible,
                handleClose,
                handleOpen,
                assigneNew,
                setAssigneNew,
                getConsiderationData,
                considerationData,
                isNeedHistory: true,
              })[activeNeedHistoryTab].render()
            }

          </>
        }
        {/* {(data?.status === 'na' || data.status === 'not_assessed' || data.status === 'monitor') && heldHistory &&
          <HeldHistoryBlock
            getCurrentLegislative={getCurrentLegislative}
            heldHistory={heldHistory}
            id={data.id}
            data={data}
          />
        } */}
        {
          isLicenseSource && data?.status === 'held' && params.tabName !== 'ia_closed' && params.tabName !== 'pending_closed' &&
          <div style={{
            color: 'var(--red)',
            fontSize: 14,
            fontFamily: 'var(--bold)',
            textDecoration: 'underline',
            cursor: 'pointer',
            marginBottom: 100,
            marginTop: 20,
          }}
            onClick={() => setVisibleNoLongerHeldModal(true)}
          >
            No Longer Held
          </div>
        }
        {
          isLicenseSource && data?.status === 'needed' && params.tabName !== 'ia_closed' && params.tabName !== 'pending_closed' &&
          <div style={{
            color: 'var(--red)',
            fontSize: 14,
            fontFamily: 'var(--bold)',
            textDecoration: 'underline',
            cursor: 'pointer',
            marginBottom: 100,
            marginTop: 20,
          }}
            onClick={() => setVisibleConfirmPendingModal(true)}
          >
            Change license status to ‘Pending Assessment’
          </div>
        }
        {
          isReportSource ? checkRole(
            [store.user?.[0]?.report_source_role],
            ['admin', 'report_owner']
          ) &&
            data?.report?.created_by_company === store.company?.[0]?.id && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 30,
                }}
              >
                {!tasks?.length && !data.assignee && (
                  <ButtonOrange
                    text='Delete Report'
                    style={{
                      color: 'var(--text)',
                      fontSize: 16,
                      cursor: 'pointer',
                      marginBottom: 50,
                    }}
                    onClick={onDelete}
                  />
                )}
                <ButtonOrange
                  text='Edit Report'
                  style={{
                    color: 'var(--text)',
                    fontSize: 16,
                    cursor: 'pointer',
                    marginBottom: 50,
                  }}
                  onClick={onEdit}
                />
              </div>
            )
            : checkRole(
              [store.user?.[0]?.member_type],
              ['admin', 'compliance_owner']
            ) &&
            data.created_by_company === store.company?.[0]?.id && showModeBlocks && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 30,
                }}
              >
                {!tasks?.length && !data.assignee && (
                  <ButtonOrange
                    text='Delete Compliance Item'
                    style={{
                      color: 'var(--text)',
                      fontSize: 16,
                      cursor: 'pointer',
                      marginBottom: 50,
                    }}
                    onClick={onDelete}
                  />
                )}

                <ButtonOrange
                  text='Edit Compliance Item'
                  style={{
                    color: 'var(--text)',
                    fontSize: 16,
                    cursor: 'pointer',
                    marginBottom: 50,
                  }}
                  onClick={onEdit}
                />
              </div>
            )
        }
        <div ref={tabsRef} />
      </Wrap >
    )
  }
)

export default Right

export const getString = (arr, toEqual) => {
  const getContent = () => {
    const description = getDescription(arr, toEqual)

    if (description?.split(',').length > 2) {
      return description
        .split(',')
        .splice(2, description.length - 1)
        .join(', ')
    } else {
      return description
    }
  }

  return (
    <>
      {getDescription(arr, toEqual)?.split(',').length > 2
        ? getDescription(arr, toEqual)
          .split(',')
          .map(
            (item, index) => index < 2 && item + (index !== 1 ? ',' : ' and')
          )
        : getDescription(arr, toEqual)}
      <Popover
        content={<div style={{ maxWidth: 150 }}>{getContent()} </div>}
        placement={'rightTop'}
      >
        <span
          style={{
            fontFamily: 'var(--bold)',
            color: 'var(--blue)',
            paddingLeft: '.5em',
          }}
        >
          {getDescription(arr, toEqual)?.split(',').length > 2 &&
            `${getDescription(arr, toEqual)?.split(',').length - 2} more`}
        </span>
      </Popover>
    </>
  )
}
